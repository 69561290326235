import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/EditarProyecto.css';

const EditarProyecto = () => {
  const { id } = useParams();
  const [proyecto, setProyecto] = useState({
    id_proyectos: '',  // Campo adicional para mostrar el Código (id_proyecto)
    Tarea: '',
    Nombre_proyecto: '',
    Direccion: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerProyecto = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/proyectos/${id}`);
        setProyecto(response.data);
      } catch (error) {
        console.error('Error al obtener el proyecto:', error);
      }
    };

    obtenerProyecto();
  }, [id]);

  const handleChange = (e) => {
    setProyecto({
      ...proyecto,
      [e.target.name]: e.target.value
    });
  };

  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/proyectos/${id}`, proyecto);
      setMensajeExito('Proyecto actualizado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/proyecto');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el proyecto:', error);
    }
  };

  return (
    <div className="gestion-proyectos">
      <h2>Editar Proyecto</h2>
      <form onSubmit={guardarCambios} className="formulario-proyecto">
        {/* Mostrar el id_proyecto como Código, pero deshabilitado para que no se edite */}
        <input
          type="text"
          name="id_proyectos"
          placeholder="Código"
          value={proyecto.id_proyectos}
          onChange={handleChange}
          disabled  // Deshabilitar la edición del código
        />
        <input
          type="text"
          name="Tarea"
          placeholder="Tarea"
          value={proyecto.Tarea}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Nombre_proyecto"
          placeholder="Nombre del Proyecto"
          value={proyecto.Nombre_proyecto}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Direccion"
          placeholder="Dirección"
          value={proyecto.Direccion}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Cambios</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarProyecto;
