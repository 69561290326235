import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/AgregarProyecto.css';

const AgregarProyecto = () => {
  const [proyecto, setProyecto] = useState({
    id_proyectos: '',
    Tarea: '',
    Nombre_proyecto: '',
    Direccion: '',
    id_cliente: '' // Agregar campo para seleccionar cliente
  });
  const [clientes, setClientes] = useState([]); // Estado para almacenar los clientes
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Obtener la lista de clientes desde el backend
    const obtenerClientes = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/clientes');
        setClientes(response.data);
      } catch (error) {
        console.error('Error al obtener los clientes:', error);
      }
    };

    obtenerClientes();
  }, []);

  const handleChange = (e) => {
    setProyecto({
      ...proyecto,
      [e.target.name]: e.target.value
    });
  };

  const agregarProyecto = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-gruj.onrender.com/api/proyectos', proyecto);
      setMensajeExito('Proyecto agregado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/proyecto');
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setMensajeError(error.response.data.error); // Mostrar mensaje de error si el código ya existe
      } else {
        console.error('Error al agregar el proyecto:', error);
        setMensajeError('Error al agregar el proyecto');
      }
    }
  };

  return (
    <div className="gestion-proyectos">
      <h2>Agregar Nuevo Proyecto</h2>
      <form onSubmit={agregarProyecto} className="formulario-proyecto">
        <label htmlFor="id_proyectos">Código</label>
        <input
          type="text"
          name="id_proyectos"
          placeholder="Código"
          value={proyecto.id_proyectos}
          onChange={handleChange}
          required
        />
        <label htmlFor="Tarea">Tarea</label>
        <input
          type="text"
          name="Tarea"
          placeholder="Tarea"
          value={proyecto.Tarea}
          onChange={handleChange}
          required
        />
        <label htmlFor="Nombre_proyecto">Nombre del Proyecto</label>
        <input
          type="text"
          name="Nombre_proyecto"
          placeholder="Nombre del Proyecto"
          value={proyecto.Nombre_proyecto}
          onChange={handleChange}
          required
        />
        <label htmlFor="Direccion">Dirección</label>
        <input
          type="text"
          name="Direccion"
          placeholder="Dirección"
          value={proyecto.Direccion}
          onChange={handleChange}
          required
        />

        {/* Selección del cliente */}
        <label htmlFor="id_cliente">Cliente</label>
        <select
          name="id_cliente"
          value={proyecto.id_cliente}
          onChange={handleChange}
          required
        >
          <option value="">Selecciona un cliente</option>
          {clientes.map((cliente) => (
            <option key={cliente.id_cliente} value={cliente.id_cliente}>
              {cliente.nombre_cliente}
            </option>
          ))}
        </select>

        <button type="submit">Guardar Proyecto</button>
      </form>

      {/* Mostrar mensaje de error o éxito */}
      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default AgregarProyecto;
