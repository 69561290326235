import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Para redireccionar
import '../../Estilos/AgregarHMateriales.css';
import ModalProyectos from '../../Componentes/ModalProyectos';
import ModalMateriales from '../../Componentes/ModalMateriales'; 

const AgregarHMateriales = () => {
  const [formData, setFormData] = useState({
    no_documento: "",
    tipo_documento: "",
    id_proyecto: "",
    contratista: "",
    fecha: "", // Campo para la fecha
    comentario: "",
    entrega: "",
    recibe: "",
    elaborado: "",
    materiales: [{ id_material: "", cantidad: "", nombre_material: "" }]
  });
  
  const [nombreProyecto, setNombreProyecto] = useState(""); 
  const [mensajeErrorProyecto, setMensajeErrorProyecto] = useState(""); 
  const [mensajeErrorMaterial, setMensajeErrorMaterial] = useState(""); 
  const [mostrarModalProyectos, setMostrarModalProyectos] = useState(false);
  const [mostrarModalMateriales, setMostrarModalMateriales] = useState(false);
  const [mensajeExito, setMensajeExito] = useState(""); // Para manejar el mensaje de éxito
  const navigate = useNavigate();

  useEffect(() => {
    if (formData.id_proyecto) {
      validarProyecto(formData.id_proyecto);
    }
  }, [formData.id_proyecto]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validarProyecto = async (idProyecto) => {
    try {
      const response = await axios.get(`https://backend-gruj.onrender.com/api/proyectos/${idProyecto}`);
      if (response.data && response.data.Nombre_proyecto) {
        setNombreProyecto(response.data.Nombre_proyecto);
        setMensajeErrorProyecto("");
      } else {
        setNombreProyecto("");
        setMensajeErrorProyecto("Proyecto no encontrado");
      }
    } catch (error) {
      setNombreProyecto("");
      setMensajeErrorProyecto("Error al verificar el proyecto");
    }
  };

  const validarMaterial = async (index) => {
    const idMaterial = formData.materiales[index].id_material;
    try {
      const response = await axios.get(`https://backend-gruj.onrender.com/api/materiales/${idMaterial}`);
      if (response.data && response.data.nombre_material) {
        const newMateriales = [...formData.materiales];
        newMateriales[index].nombre_material = response.data.nombre_material;
        setFormData({ ...formData, materiales: newMateriales });
        setMensajeErrorMaterial("");
      } else {
        const newMateriales = [...formData.materiales];
        newMateriales[index].nombre_material = "";
        setFormData({ ...formData, materiales: newMateriales });
        setMensajeErrorMaterial("Material no encontrado");
      }
    } catch (error) {
      const newMateriales = [...formData.materiales];
      newMateriales[index].nombre_material = "";
      setFormData({ ...formData, materiales: newMateriales });
      setMensajeErrorMaterial("Error al verificar el material");
    }
  };

  const handleMaterialChange = (index, e) => {
    const newMateriales = [...formData.materiales];
    newMateriales[index][e.target.name] = e.target.value;
    setFormData({ ...formData, materiales: newMateriales });

    if (e.target.name === "id_material") {
      validarMaterial(index);
    }
  };

  const addMaterial = () => {
    setFormData({
      ...formData,
      materiales: [...formData.materiales, { id_material: "", cantidad: "", nombre_material: "" }]
    });
    setMensajeErrorMaterial("");  // Limpia el mensaje de error al agregar un nuevo material
  };

  const removeMaterial = (index) => {
    const newMateriales = formData.materiales.filter((_, i) => i !== index);
    setFormData({ ...formData, materiales: newMateriales });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://backend-gruj.onrender.com/api/h_materiales', formData)
      .then((response) => {
        console.log("Registro agregado exitosamente:", response.data);
        setMensajeExito("Hoja de materiales agregada con éxito");
        
        // Limpiar formulario
        setFormData({
          no_documento: "",
          tipo_documento: "",
          id_proyecto: "",
          contratista: "",
          fecha: "", // Limpiar la fecha
          comentario: "",
          entrega: "",
          recibe: "",
          elaborado: "",
          materiales: [{ id_material: "", cantidad: "", nombre_material: "" }]
        });
        setNombreProyecto("");
        setMensajeErrorProyecto("");

        // Mostrar mensaje de éxito y redirigir después de 2 segundos
        setTimeout(() => {
          navigate('/vista-administrativo/h-materiales');
        }, 2000);
      })
      .catch((error) => {
        console.error("Error al agregar la hoja de materiales:", error);
      });
  };

  return (
    <div className="container">
      <h1>Agregar Hoja de Materiales</h1>
      <form onSubmit={handleSubmit}>
        {/* No. Documento y Tipo Documento */}
        <div className="group-horizontal">
          <div className="form-group">
            <label>No. Documento</label>
            <input
              type="text"
              name="no_documento"
              value={formData.no_documento}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Tipo Documento</label>
            <select
              name="tipo_documento"
              value={formData.tipo_documento}
              onChange={handleInputChange}
              required
            >
              <option value="">Seleccione...</option>
              <option value="1">Salida</option>
              <option value="2">Devolución</option>
            </select>
          </div>
        </div>

        {/* Id Proyecto y Nombre del Proyecto */}
        <div className="group-horizontal">
          <div className="form-group input-with-button">
            <label>Id Proyecto</label>
            <div className="input-row">
              <input
                type="text"
                name="id_proyecto"
                value={formData.id_proyecto}
                onChange={handleInputChange}
                required
              />
              <button type="button" className="btn btn-info" onClick={() => setMostrarModalProyectos(true)}>
                Buscar Proyecto
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>Nombre del Proyecto</label>
            <input
              type="text"
              value={nombreProyecto || mensajeErrorProyecto}
              readOnly
            />
          </div>
        </div>

        {/* Contratista, Fecha, Entrega, Recibe y Elaborado */}
        <div className="group-horizontal">
          <div className="form-group">
            <label>Contratista</label>
            <select
              name="contratista"
              value={formData.contratista}
              onChange={handleInputChange}
              required
            >
              <option value="">Seleccione un contratista...</option>
              <option value="1">LGB</option>
              <option value="2">Cable Color</option>
            </select>
          </div>
          <div className="form-group">
            <label>Fecha</label>
            <input
              type="date"
              name="fecha"
              value={formData.fecha}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="group-horizontal">
          <div className="form-group">
            <label>Entrega</label>
            <input
              type="text"
              name="entrega"
              value={formData.entrega}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Recibe</label>
            <input
              type="text"
              name="recibe"
              value={formData.recibe}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Elaborado</label>
            <input
              type="text"
              name="elaborado"
              value={formData.elaborado}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        {/* Comentario */}
        <div className="form-group">
          <label>Comentario</label>
          <textarea
            name="comentario"
            value={formData.comentario}
            onChange={handleInputChange}
          />
        </div>

        {/* Materiales */}
        <h3>Materiales</h3>
        {formData.materiales.map((material, index) => (
          <div key={index} className="material-group">
            <div className="input-with-button">
              <input
                type="text"
                name="id_material"
                placeholder="ID Material"
                value={material.id_material}
                onChange={(e) => handleMaterialChange(index, e)}
                required
              />
              <button type="button" className="btn btn-info" onClick={() => setMostrarModalMateriales(true)}>
                Buscar Material
              </button>
            </div>
            <input
              type="text"
              placeholder="Nombre del Material"
              value={material.nombre_material || mensajeErrorMaterial}
              readOnly
            />
            <input
              type="number"
              name="cantidad"
              placeholder="Cantidad"
              value={material.cantidad}
              onChange={(e) => handleMaterialChange(index, e)}
              required
            />
            <button type="button" onClick={() => removeMaterial(index)} className="btn btn-danger">
              Eliminar
            </button>
          </div>
        ))}

        <button type="button" onClick={addMaterial} className="btn btn-primary">
          Agregar Material
        </button>

        <button type="submit" className="btn btn-success">
          Guardar
        </button>
      </form>

      {/* Mostrar mensaje de éxito */}
      {mensajeExito && (
        <div className="alert alert-success mt-3">
          {mensajeExito}
        </div>
      )}

      {/* Modal de Proyectos */}
      <ModalProyectos 
        isOpen={mostrarModalProyectos} 
        onClose={() => setMostrarModalProyectos(false)} 
      />

      {/* Modal de Materiales */}
      <ModalMateriales 
        isOpen={mostrarModalMateriales} 
        onClose={() => setMostrarModalMateriales(false)} 
      />
    </div>
  );
};

export default AgregarHMateriales;
