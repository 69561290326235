import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BarraNavegacion from '../Componentes/BarraNavegacion';  // Importa el encabezado
import PiePagina from '../Componentes/PiePagina';  // Importa el pie de página
import '../Estilos/IniciarSesion.css';

const IniciarSesion = () => {
    const [nombre_usuario, setNombreUsuario] = useState('');
    const [contraseña, setContraseña] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch('https://backend-gruj.onrender.com/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ nombre_usuario, contraseña })
        });

        const data = await response.json();

        if (data.success) {
            if (data.rol === 'administrativo') {
                navigate('/vista-administrativo');
            } else if (data.rol === 'tecnico') {
                navigate('/vista-tecnico');
            }
        } else {
            alert('Credenciales incorrectas');
        }
    };

    return (
        <div>
            <BarraNavegacion />  {/* Encabezado */}
            <div className="iniciar-sesion">
                <h2>Iniciar Sesión</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="username">Nombre de usuario:</label>
                        <input 
                            type="text" 
                            id="username" 
                            value={nombre_usuario}
                            onChange={(e) => setNombreUsuario(e.target.value)}
                            required 
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Contraseña:</label>
                        <input 
                            type="password" 
                            id="password" 
                            value={contraseña}
                            onChange={(e) => setContraseña(e.target.value)}
                            required 
                        />
                    </div>
                    <button type="submit">Iniciar Sesión</button>
                </form>
            </div>
            <PiePagina />  {/* Pie de página */}
        </div>
    );
};

export default IniciarSesion;
