import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/TipoDocumento.css';

const AgregarTipoDocumento = () => {
  const [nuevoTipoDocumento, setNuevoTipoDocumento] = useState({
    nombre_documento: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setNuevoTipoDocumento({
      ...nuevoTipoDocumento,
      [e.target.name]: e.target.value,
    });
  };

  const agregarTipoDocumento = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-gruj.onrender.com/api/tipos-documento', nuevoTipoDocumento);
      setMensajeExito('Tipo de documento guardado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/tipo-documento');
      }, 2000);
    } catch (error) {
      console.error('Error al agregar el tipo de documento:', error);
    }
  };

  return (
    <div className="gestion-tipo-documento">
      <h2>Agregar Nuevo Tipo de Documento</h2>
      <form onSubmit={agregarTipoDocumento} className="formulario-tipo-documento">
        <input
          type="text"
          name="nombre_documento"
          placeholder="Nombre del Documento"
          value={nuevoTipoDocumento.nombre_documento}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Documento</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default AgregarTipoDocumento;
