import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/EditarUsuario.css';

const EditarUsuario = () => {
  const { id } = useParams();
  const [usuario, setUsuario] = useState({
    Nombre_usuarios: '',
    Contraseña: '',
    id_roles: '',
    id_empleados: ''
  });
  const [mostrarContraseña, setMostrarContraseña] = useState(false); // Estado para mostrar/ocultar contraseña
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerUsuario = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/usuarios/${id}`);
        setUsuario(response.data);
      } catch (error) {
        console.error('Error al obtener el usuario:', error);
      }
    };

    obtenerUsuario();
  }, [id]);

  const handleChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value
    });
  };

  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/usuarios/${id}`, usuario);
      setMensajeExito('Usuario actualizado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/gestion-usuarios');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
    }
  };

  return (
    <div className="editar-usuario">
      <h2>Editar Usuario</h2>
      <form onSubmit={guardarCambios} className="formulario-editar-usuario">
        <label htmlFor="Nombre_usuarios">Nombre de Usuario:</label>
        <input
          type="text"
          name="Nombre_usuarios"
          value={usuario.Nombre_usuarios}
          onChange={handleChange}
          required
        />

        <label htmlFor="Contraseña">Contraseña:</label>
        <div className="input-con-boton">
          <input
            type={mostrarContraseña ? 'text' : 'password'} // Mostrar u ocultar contraseña
            name="Contraseña"
            value={usuario.Contraseña}
            onChange={handleChange}
            required
          />
          <button
            type="button"
            onClick={() => setMostrarContraseña(!mostrarContraseña)} // Alternar visibilidad de la contraseña
          >
            {mostrarContraseña ? 'Ocultar' : 'Mostrar'}
          </button>
        </div>

        <label htmlFor="id_roles">Rol:</label>
        <input
          type="text"
          name="id_roles"
          value={usuario.id_roles}
          onChange={handleChange}
          required
        />

        <label htmlFor="id_empleados">Empleado Asociado:</label>
        <input
          type="text"
          name="id_empleados"
          value={usuario.id_empleados}
          onChange={handleChange}
          required
        />

        <button type="submit">Guardar Cambios</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarUsuario;
