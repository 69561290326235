import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Empleados.css';

const Empleados = () => {
  const [empleados, setEmpleados] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerEmpleados = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/empleados');
        setEmpleados(response.data);
      } catch (error) {
        console.error('Error al obtener los empleados:', error);
      }
    };

    obtenerEmpleados();
  }, []);

  const eliminarEmpleado = async (id) => {
    const confirmarEliminar = window.confirm('¿Estás seguro de que quieres eliminar este empleado?');
    if (!confirmarEliminar) return;

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/empleados/${id}`);
      setEmpleados(empleados.filter(emp => emp.id_empleados !== id));
    } catch (error) {
      console.error('Error al eliminar el empleado:', error);
    }
  };

  const agregarEmpleado = () => {
    navigate('/vista-administrativo/empleados/agregar');
  };

  const editarEmpleado = (id) => {
    navigate(`/vista-administrativo/empleados/editar/${id}`);
  };

  return (
    <div className="gestion-empleados">
      <h2>Gestión de Empleados</h2>
      <button className="boton-agregar-empleado" onClick={agregarEmpleado}>Agregar Empleado</button>

      <table className="tabla-empleados">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Cargo</th>
            <th>DPI</th>
            <th>Teléfono</th>
            <th>Dirección</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {empleados.map((empleado) => (
            <tr key={empleado.id_empleados}>
              <td>{empleado.Nombre_empleado}</td>
              <td>{empleado.Apellido_empleado}</td>
              <td>{empleado.Cargo}</td>
              <td>{empleado.DPI}</td>
              <td>{empleado.Telefono}</td>
              <td>{empleado.Direccion}</td>
              <td>
                <button className="boton-editar" onClick={() => editarEmpleado(empleado.id_empleados)}>Editar</button>
                <button className="boton-eliminar" onClick={() => eliminarEmpleado(empleado.id_empleados)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Empleados;
