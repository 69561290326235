import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/AgregarUsuario.css';

const AgregarUsuario = () => {
  const [nuevoUsuario, setNuevoUsuario] = useState({
    Nombre_usuarios: '',
    Contraseña: '',
    id_roles: '',
    id_empleados: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setNuevoUsuario({
      ...nuevoUsuario,
      [e.target.name]: e.target.value,
    });
  };

  const agregarUsuario = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-gruj.onrender.com/api/usuarios', nuevoUsuario);
      setMensajeExito('Usuario agregado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/gestion-usuarios');
      }, 2000);
    } catch (error) {
      console.error('Error al agregar el usuario:', error);
    }
  };

  return (
    <div className="gestion-usuarios">
      <h2>Agregar Nuevo Usuario</h2>
      <form onSubmit={agregarUsuario} className="formulario-usuario">
        <input
          type="text"
          name="Nombre_usuarios"
          placeholder="Nombre de Usuario"
          value={nuevoUsuario.Nombre_usuarios}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="Contraseña"
          placeholder="Contraseña"
          value={nuevoUsuario.Contraseña}
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="id_roles"
          placeholder="ID Rol"
          value={nuevoUsuario.id_roles}
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="id_empleados"
          placeholder="ID Empleado"
          value={nuevoUsuario.id_empleados}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Usuario</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default AgregarUsuario;
