import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import '../../Estilos/EstatusProyecto.css'; // Importamos el CSS para darle estilo
import ModalProyectos from '../../Componentes/ModalProyectos'; // Importa el modal
import moment from 'moment'; // Importamos moment para manejar la fecha

const EstatusProyecto = () => {
  const [idProyecto, setIdProyecto] = useState('');
  const [materiales, setMateriales] = useState([]);
  const [mensajeError, setMensajeError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
  const [fechaConsulta, setFechaConsulta] = useState(''); // Estado para manejar la fecha de consulta

  const buscarProyecto = async () => {
    try {
      const response = await axios.get(`https://backend-gruj.onrender.com/api/estatus-proyecto/${idProyecto}`);
      if (response.data.length > 0) {
        setMateriales(response.data); // Almacena todos los materiales
        setFechaConsulta(moment().format('DD/MM/YYYY')); // Establecemos la fecha actual cuando se realiza la consulta
        setMensajeError(''); // Limpia cualquier mensaje de error
      } else {
        setMensajeError('No se encontró información para este proyecto.');
        setMateriales([]);
      }
    } catch (error) {
      console.error('Error al obtener los datos del proyecto:', error);
      setMensajeError('No se pudo obtener la información del proyecto');
      setMateriales([]); // Limpia los datos anteriores si hay un error
    }
  };

  const generarExcel = () => {
    const ws = XLSX.utils.json_to_sheet(materiales);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Materiales');
    XLSX.writeFile(wb, `Estatus_Proyecto_${idProyecto}.xlsx`);
  };

  const handleSelectProject = (id) => {
    setIdProyecto(id); // Actualiza el ID del proyecto seleccionado
    setIsModalOpen(false); // Cierra el modal
  };

  return (
    <div className="estatus-proyecto">
      <h1>Estatus del Proyecto</h1>
      <div className="busqueda">
        <label htmlFor="idProyecto">ID Proyecto:</label>
        <input
          type="text"
          id="idProyecto"
          value={idProyecto}
          onChange={(e) => setIdProyecto(e.target.value)}
        />
        <button onClick={buscarProyecto}>Obtener Estatus</button>
        <button onClick={() => setIsModalOpen(true)}>Buscar Proyecto</button> {/* Botón para abrir el modal */}
      </div>

      {mensajeError && <p className="error">{mensajeError}</p>}

      {materiales.length > 0 && (
        <div>
          <h2>Información del Proyecto:</h2>
          <p><strong>Empresa:</strong> {materiales[0].empresa || 'No disponible'}</p>
          <p><strong>Tarea:</strong> {materiales[0].tarea || 'No disponible'}</p>
          <p><strong>Ubicación:</strong> {materiales[0].ubicacion || 'No disponible'}</p>
          <p><strong>Fecha:</strong> {fechaConsulta || 'No disponible'}</p> {/* Mostramos la fecha actual */}

          <h3>Materiales:</h3>
          <table className="tabla-materiales">
            <thead>
              <tr>
                <th>Código Material</th>
                <th>Descripción Material</th>
                <th>Medida</th>
                <th>Total Salidas</th>
                <th>Total Devoluciones</th>
                <th>Descuentos</th>
                <th>Total General</th>
                <th>Producción</th>
                <th>Diferencia</th>
              </tr>
            </thead>
            <tbody>
              {materiales.map((material, index) => (
                <tr key={index}>
                  <td>{material.codigo || 'No disponible'}</td>
                  <td>{material.descripcion || 'No disponible'}</td>
                  <td>{material.medida || 'No disponible'}</td>
                  <td>{material.total_salidas}</td>
                  <td>{material.total_devoluciones}</td>
                  <td>{material.descuentos}</td>
                  <td>{material.total_general}</td>
                  <td>{material.produccion}</td>
                  <td>{(material.total_general - material.produccion).toFixed(2)}</td> {/* Calculamos la diferencia y la formateamos */}
                </tr>
              ))}
            </tbody>
          </table>

          <button onClick={generarExcel}>Descargar Excel</button>
        </div>
      )}

      {/* Aquí invocamos el modal */}
      <ModalProyectos
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelectProject={handleSelectProject}
      />
    </div>
  );
};

export default EstatusProyecto;