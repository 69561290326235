import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../Estilos/Cliente.css';

const Cliente = () => {
  const [clientes, setClientes] = useState([]);

  // Función para obtener todos los clientes
  const fetchClientes = async () => {
    try {
      const response = await axios.get('https://backend-gruj.onrender.com/api/clientes');
      setClientes(response.data);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
    }
  };

  useEffect(() => {
    fetchClientes();
  }, []);

  // Función para eliminar un cliente
  const eliminarCliente = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este cliente?')) {
      try {
        await axios.delete(`https://backend-gruj.onrender.com/api/clientes/${id}`);
        fetchClientes(); // Actualizar la lista de clientes después de eliminar
      } catch (error) {
        console.error('Error al eliminar el cliente:', error);
      }
    }
  };

  return (
    <div className="gestion-clientes">
      <h2>Gestión de Clientes</h2>
      <Link to="/vista-administrativo/cliente/agregar">
        <button className="boton-agregar-cliente">Agregar Cliente</button>
      </Link>
      <table className="tabla-clientes">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Teléfono</th>
            <th>Dirección</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {clientes.map((cliente) => (
            <tr key={cliente.id_cliente}>
              <td>{cliente.nombre_cliente}</td>
              <td>{cliente.correo_cliente}</td>
              <td>{cliente.telefono}</td>
              <td>{cliente.direccion}</td>
              <td>
                <Link to={`/vista-administrativo/cliente/editar/${cliente.id_cliente}`}>
                  <button className="boton-editar">Editar</button>
                </Link>
                <button className="boton-eliminar" onClick={() => eliminarCliente(cliente.id_cliente)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Cliente;
