import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Estilos/ModalMateriales.css';

const ModalMateriales = ({ isOpen, onClose }) => {
  const [materiales, setMateriales] = useState([]);
  const [filtroId, setFiltroId] = useState('');
  const [filtroNombre, setFiltroNombre] = useState('');

  useEffect(() => {
    const obtenerMateriales = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/materiales');
        console.log(response.data); // Verificar la estructura de los datos
        setMateriales(response.data);
      } catch (error) {
        console.error('Error al obtener los materiales', error);
      }
    };

    if (isOpen) {
      obtenerMateriales();
    }
  }, [isOpen]);

  const materialesFiltrados = materiales.filter((material) => {
    return (
      material.id_materiales.toLowerCase().includes(filtroId.toLowerCase()) &&
      material.nombre_material.toLowerCase().includes(filtroNombre.toLowerCase())
    );
  });

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Información de Materiales</h2>
          <button className="modal-close" onClick={onClose}>
            X
          </button>
        </div>
        <div className="modal-filters">
          <input
            type="text"
            placeholder="Filtrar por ID"
            value={filtroId}
            onChange={(e) => setFiltroId(e.target.value)}
            className="modal-filter-input"
          />
          <input
            type="text"
            placeholder="Filtrar por Nombre"
            value={filtroNombre}
            onChange={(e) => setFiltroNombre(e.target.value)}
            className="modal-filter-input"
          />
        </div>
        <div className="modal-content">
          <table className="material-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Unidad de Medida</th>
                <th>Cliente</th>
              </tr>
            </thead>
            <tbody>
              {materialesFiltrados.map((material) => (
                <tr key={material.id_materiales}>
                  <td>{material.id_materiales}</td>
                  <td>{material.nombre_material}</td>
                  <td>{material.unidad_medida}</td>
                  <td>{material.nombre_cliente}</td> {/* Asegúrate de usar 'nombre_cliente' */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ModalMateriales;
