import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/TipoDocumento.css'; // Asegúrate de que los estilos se carguen correctamente

const TipoDocumento = () => {
  const [tiposDocumento, setTiposDocumento] = useState([]);
  const navigate = useNavigate();

  // Obtener la lista de tipos de documento desde el backend
  useEffect(() => {
    const obtenerTiposDocumento = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/tipos-documento');
        setTiposDocumento(response.data);
      } catch (error) {
        console.error('Error al obtener los tipos de documento:', error);
      }
    };

    obtenerTiposDocumento();
  }, []);

  // Función para eliminar un tipo de documento
  const eliminarTipoDocumento = async (id) => {
    // Confirmación antes de eliminar
    const confirmarEliminar = window.confirm('¿Estás seguro de que quieres eliminar este tipo de documento?');
    if (!confirmarEliminar) return; // Si cancela, no se ejecuta el código de eliminación

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/tipos-documento/${id}`);
      setTiposDocumento(tiposDocumento.filter(tipo => tipo.id_tipo_documento !== id)); // Actualizar la lista de tipos
    } catch (error) {
      console.error('Error al eliminar el tipo de documento:', error);
    }
  };

  // Navegar a la vista de agregar tipo de documento
  const agregarTipoDocumento = () => {
    navigate('/vista-administrativo/tipo-documento/agregar');
  };

  // Navegar a la vista de editar tipo de documento
  const editarTipoDocumento = (id) => {
    navigate(`/vista-administrativo/tipo-documento/editar/${id}`);
  };

  return (
    <div className="gestion-tipo-documento">
      <h2>Gestión de Tipos de Documento</h2>
      <button className="boton-agregar-tipo-documento" onClick={agregarTipoDocumento}>Agregar Tipo de Documento</button>

      <table className="tabla-tipo-documento">
        <thead>
          <tr>
            <th>Nombre Documento</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {tiposDocumento.map((tipo) => (
            <tr key={tipo.id_tipo_documento}>
              <td>{tipo.nombre_documento}</td>
              <td>
                <button className="boton-editar" onClick={() => editarTipoDocumento(tipo.id_tipo_documento)}>Editar</button>
                <button className="boton-eliminar" onClick={() => eliminarTipoDocumento(tipo.id_tipo_documento)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TipoDocumento;
