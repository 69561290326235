import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/AgregarEmpleado.css';

const EditarEmpleado = () => {
  const { id } = useParams();
  const [empleado, setEmpleado] = useState({
    Nombre_empleado: '',
    Apellido_empleado: '',
    Cargo: '',
    DPI: '',
    Telefono: '',
    Direccion: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerEmpleado = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/empleados/${id}`);
        setEmpleado(response.data);
      } catch (error) {
        console.error('Error al obtener el empleado:', error);
      }
    };

    obtenerEmpleado();
  }, [id]);

  const handleChange = (e) => {
    setEmpleado({
      ...empleado,
      [e.target.name]: e.target.value
    });
  };

  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/empleados/${id}`, empleado);
      setMensajeExito('Empleado actualizado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/empleados');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el empleado:', error);
    }
  };

  return (
    <div className="gestion-empleados">
      <h2>Editar Empleado</h2>
      <form onSubmit={guardarCambios} className="formulario-empleado">
        <input
          type="text"
          name="Nombre_empleado"
          placeholder="Nombre del Empleado"
          value={empleado.Nombre_empleado}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Apellido_empleado"
          placeholder="Apellido del Empleado"
          value={empleado.Apellido_empleado}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Cargo"
          placeholder="Cargo"
          value={empleado.Cargo}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="DPI"
          placeholder="DPI"
          value={empleado.DPI}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Telefono"
          placeholder="Teléfono"
          value={empleado.Telefono}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Direccion"
          placeholder="Dirección"
          value={empleado.Direccion}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Cambios</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarEmpleado;
