import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../../Estilos/EditarDescuentos.css';

const EditarDescuento = () => {
  const { id } = useParams(); // Obtenemos el ID del descuento
  const [idProyecto, setIdProyecto] = useState('');
  const [idMateriales, setIdMateriales] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [descuentoUnitario, setDescuentoUnitario] = useState('');
  const [fecha, setFecha] = useState(''); // Campo para la fecha
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  // Obtener los datos del descuento por ID
  useEffect(() => {
    const obtenerDescuento = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/descuentos/${id}`);
        const descuento = response.data;
        setIdProyecto(descuento.id_proyecto);
        setIdMateriales(descuento.id_materiales);
        setCantidad(descuento.cantidad);
        setDescuentoUnitario(descuento.descuento_unitario);
        setFecha(descuento.fecha.split('T')[0]); // Asignar la fecha al formato YYYY-MM-DD
      } catch (error) {
        console.error('Error al obtener el descuento', error);
        setMensajeError('Hubo un error al obtener el descuento');
      }
    };

    obtenerDescuento();
  }, [id]);

  // Actualizar el descuento
  const actualizarDescuento = async (e) => {
    e.preventDefault();

    try {
      const descuentoActualizado = {
        id_proyecto: idProyecto,
        id_materiales: idMateriales,
        cantidad,
        descuento_unitario: descuentoUnitario,
        fecha, // Enviamos la fecha al backend
      };

      await axios.put(`https://backend-gruj.onrender.com/api/descuentos/${id}`, descuentoActualizado);
      setMensajeExito('Descuento actualizado exitosamente');
      
      // Navegar a la lista de descuentos después de 2 segundos
      setTimeout(() => {
        navigate('/vista-administrativo/descuentos');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el descuento', error);
      setMensajeError('Hubo un error al actualizar el descuento');
    }
  };

  return (
    <div className="editar-descuento-container">
      <h2>Editar Descuento</h2>
      <form onSubmit={actualizarDescuento} className="formulario-editar-descuento">
        
        {/* Campo para la fecha */}
        <div>
          <label>Fecha:</label>
          <input
            type="date"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Proyecto:</label>
          <input
            type="text"
            value={idProyecto}
            onChange={(e) => setIdProyecto(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Material:</label>
          <input
            type="text"
            value={idMateriales}
            onChange={(e) => setIdMateriales(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Cantidad:</label>
          <input
            type="number"
            min="1"
            value={cantidad}
            onChange={(e) => setCantidad(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Descuento Unitario (Q):</label>
          <input
            type="number"
            step="0.01"
            min="0"
            value={descuentoUnitario}
            onChange={(e) => setDescuentoUnitario(e.target.value)}
            required
          />
        </div>

        <button type="submit">Actualizar Descuento</button>
      </form>

      {/* Mostrar mensaje de error o éxito */}
      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarDescuento;
