import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Proyectos.css';

const Proyectos = () => {
  const [proyectos, setProyectos] = useState([]);
  const [filtroCodigo, setFiltroCodigo] = useState('');  // Estado para el filtro por código
  const [filtroNombre, setFiltroNombre] = useState('');  // Estado para el filtro por nombre de proyecto
  const [filtroCliente, setFiltroCliente] = useState(''); // Estado para el filtro por nombre de cliente
  const [clientes, setClientes] = useState([]); // Almacenar clientes para filtro
  const navigate = useNavigate();

  // Obtener la lista de proyectos y clientes desde el backend
  useEffect(() => {
    const obtenerProyectos = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/proyectos');
        setProyectos(response.data);
      } catch (error) {
        console.error('Error al obtener los proyectos:', error);
      }
    };

    const obtenerClientes = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/clientes');
        setClientes(response.data);
      } catch (error) {
        console.error('Error al obtener los clientes:', error);
      }
    };

    obtenerProyectos();
    obtenerClientes();
  }, []);

  // Función para eliminar un proyecto
  const eliminarProyecto = async (id) => {
    const confirmarEliminar = window.confirm('¿Estás seguro de que quieres eliminar este proyecto?');
    if (!confirmarEliminar) return;

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/proyectos/${id}`);
      setProyectos(proyectos.filter(proyecto => proyecto.id_proyectos !== id));
    } catch (error) {
      console.error('Error al eliminar el proyecto:', error);
    }
  };

  // Navegar a la vista de agregar proyecto
  const agregarProyecto = () => {
    navigate('/vista-administrativo/proyecto/agregar');
  };

  // Navegar a la vista de editar proyecto
  const editarProyecto = (id) => {
    navigate(`/vista-administrativo/proyecto/editar/${id}`);
  };

  // Filtrar los proyectos según los filtros ingresados
  const proyectosFiltrados = proyectos.filter(proyecto =>
    proyecto.id_proyectos.toString().includes(filtroCodigo) &&
    proyecto.Nombre_proyecto.toLowerCase().includes(filtroNombre.toLowerCase()) &&
    (proyecto.nombre_cliente || '').toLowerCase().includes(filtroCliente.toLowerCase())
  );

  return (
    <div className="gestion-proyectos">
      <h2>Gestión de Proyectos</h2>
      <div className="acciones-proyectos">
        <button className="boton-agregar-proyecto" onClick={agregarProyecto}>Agregar Proyecto</button>

        {/* Campo para filtrar por código */}
        <input
          type="text"
          placeholder="Filtrar por código"
          value={filtroCodigo}
          onChange={(e) => setFiltroCodigo(e.target.value)}
          className="filtro-codigo"
        />

        {/* Campo para filtrar por nombre de proyecto */}
        <input
          type="text"
          placeholder="Filtrar por nombre"
          value={filtroNombre}
          onChange={(e) => setFiltroNombre(e.target.value)}
          className="filtro-nombre"
        />

        {/* Campo para filtrar por cliente */}
        <input
          type="text"
          placeholder="Filtrar por cliente"
          value={filtroCliente}
          onChange={(e) => setFiltroCliente(e.target.value)}
          className="filtro-cliente"
        />
      </div>

      <table className="tabla-proyectos">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nombre del Proyecto</th>
            <th>Dirección</th> {/* Añadimos la columna de Dirección */}
            <th>Cliente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {proyectosFiltrados.length > 0 ? (
            proyectosFiltrados.map((proyecto) => (
              <tr key={proyecto.id_proyectos}>
                <td>{proyecto.id_proyectos}</td>
                <td>{proyecto.Nombre_proyecto}</td>
                <td>{proyecto.Direccion || 'N/A'}</td> {/* Mostrar dirección o 'N/A' */}
                <td>{proyecto.nombre_cliente || 'N/A'}</td> {/* Mostrar 'N/A' si no hay cliente */}
                <td>
                  <button className="boton-acciones editar" onClick={() => editarProyecto(proyecto.id_proyectos)}>Editar</button>
                  <button className="boton-acciones eliminar" onClick={() => eliminarProyecto(proyecto.id_proyectos)}>Eliminar</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No hay proyectos disponibles</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Proyectos;