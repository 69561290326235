import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/AgregarEmpleado.css';

const AgregarEmpleado = () => {
  const [nuevoEmpleado, setNuevoEmpleado] = useState({
    Nombre_empleado: '',
    Apellido_empleado: '',
    Puesto: '', // Añadimos el campo Puesto
    Cargo: '',
    DPI: '',
    Telefono: '',
    Direccion: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setNuevoEmpleado({
      ...nuevoEmpleado,
      [e.target.name]: e.target.value
    });
  };

  const agregarEmpleado = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-gruj.onrender.com/api/empleados', nuevoEmpleado);
      setMensajeExito('Empleado agregado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/empleados');
      }, 2000);
    } catch (error) {
      console.error('Error al agregar empleado:', error);
    }
  };

  return (
    <div className="gestion-empleados">
      <h2>Agregar Nuevo Empleado</h2>
      <form onSubmit={agregarEmpleado} className="formulario-empleado">
        <input
          type="text"
          name="Nombre_empleado"
          placeholder="Nombre del Empleado"
          value={nuevoEmpleado.Nombre_empleado}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Apellido_empleado"
          placeholder="Apellido del Empleado"
          value={nuevoEmpleado.Apellido_empleado}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Puesto" // Campo añadido
          placeholder="Puesto"
          value={nuevoEmpleado.Puesto}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Cargo"
          placeholder="Cargo"
          value={nuevoEmpleado.Cargo}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="DPI"
          placeholder="DPI"
          value={nuevoEmpleado.DPI}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Telefono"
          placeholder="Teléfono"
          value={nuevoEmpleado.Telefono}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="Direccion"
          placeholder="Dirección"
          value={nuevoEmpleado.Direccion}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Empleado</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default AgregarEmpleado;
