import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/EditarHistorialProyectos.css';

const EditarHistorialProyectos = () => {
  const { id } = useParams(); // Obtenemos el id del historial desde los parámetros de la URL
  const [id_proyectos, setIdProyectos] = useState('');
  const [Fecha_inicio, setFechaInicio] = useState('');
  const [Fecha_fin, setFechaFin] = useState('');
  const [Descripcion_trabajo, setDescripcionTrabajo] = useState('');
  const [Fecha_liquidado, setFechaLiquidado] = useState('');
  const [Liquidado, setLiquidado] = useState(false);
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  // Efecto para cargar los datos del historial cuando el componente se monte
  useEffect(() => {
    const obtenerHistorial = async () => {
        try {
            const respuesta = await axios.get(`https://backend-gruj.onrender.com/api/historial-proyectos/${id}`);
            const historial = respuesta.data;

            if (historial) {
                setIdProyectos(historial.id_proyectos);
                setFechaInicio(historial.Fecha_inicio ? historial.Fecha_inicio.split('T')[0] : '');
                setFechaFin(historial.Fecha_fin ? historial.Fecha_fin.split('T')[0] : '');
                setDescripcionTrabajo(historial.Descripcion_trabajo || '');
                setFechaLiquidado(historial.Fecha_liquidado ? historial.Fecha_liquidado.split('T')[0] : '');
                setLiquidado(historial.Liquidado === 'Sí');
            }
        } catch (error) {
            setMensajeError('Error al cargar el historial de proyectos.');
        }
    };

    obtenerHistorial();
}, [id]);


  const manejarSubmit = async (e) => {
    e.preventDefault();

    // Validar si el proyecto existe antes de actualizar
    try {
      const respuesta = await axios.get(`https://backend-gruj.onrender.com/api/proyectos/${id_proyectos}`);
      if (!respuesta.data) {
        setMensajeError('El ID del proyecto no existe.');
        return;
      }
    } catch (error) {
      setMensajeError('Error al verificar el ID del proyecto.');
      return;
    }

    // Actualizar el historial de proyectos
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/historial-proyectos/${id}`, {
    id_proyectos,
    Fecha_inicio,
    Fecha_fin,
    Descripcion_trabajo,
    Fecha_liquidado,
    Liquidado: Liquidado ? 'Sí' : 'No'
});
      setMensajeExito('Historial actualizado correctamente.');
      setTimeout(() => {
        navigate('/vista-administrativo/historial-proyectos');
      }, 2000);
    } catch (error) {
      setMensajeError('Ocurrió un error al actualizar el historial.');
    }
  };

  return (
    <div className="editar-historial">
      <h2>Editar Historial de Proyecto</h2>
      {mensajeError && <div className="mensaje-error">{mensajeError}</div>}
      {mensajeExito && <div className="mensaje-exito">{mensajeExito}</div>}
      <form onSubmit={manejarSubmit}>
        <div className="form-group">
          <label>ID Proyecto</label>
          <input
            type="text"
            value={id_proyectos}
            onChange={(e) => setIdProyectos(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Fecha de Inicio</label>
          <input
            type="date"
            value={Fecha_inicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Fecha de Fin</label>
          <input
            type="date"
            value={Fecha_fin}
            onChange={(e) => setFechaFin(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Descripción del Trabajo</label>
          <input
            type="text"
            value={Descripcion_trabajo}
            onChange={(e) => setDescripcionTrabajo(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Fecha Liquidado</label>
          <input
            type="date"
            value={Fecha_liquidado}
            onChange={(e) => setFechaLiquidado(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Liquidado</label>
          <input
            type="checkbox"
            checked={Liquidado}
            onChange={(e) => setLiquidado(e.target.checked)}
          />
        </div>

        <button type="submit" className="boton-guardar">
          Actualizar Historial
        </button>
      </form>
    </div>
  );
};

export default EditarHistorialProyectos;
