import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../Estilos/HistorialProyectos.css';

const HistorialProyectos = () => {
    const [historial, setHistorial] = useState([]);
    const [filtroIdProyecto, setFiltroIdProyecto] = useState('');
    const [filtroLiquidado, setFiltroLiquidado] = useState('');
    const navigate = useNavigate();

    // Cargar el historial de proyectos desde la base de datos
    useEffect(() => {
        const obtenerHistorial = async () => {
            try {
                const response = await axios.get('https://backend-gruj.onrender.com/api/historial-proyectos');
                setHistorial(response.data);
            } catch (error) {
                console.error('Error al obtener el historial:', error);
            }
        };
        obtenerHistorial();
    }, []);

    // Navegar a la página para agregar un nuevo historial
    const agregarHistorial = () => {
        navigate('/vista-administrativo/historial-proyectos/agregar');
    };

    // Navegar a la página de edición de un historial específico
    const editarHistorial = (id) => {
        navigate(`/vista-administrativo/historial-proyectos/editar/${id}`);
    };

    // Eliminar un historial por su ID
    const eliminarHistorial = async (id) => {
        if (window.confirm('¿Estás seguro de eliminar este historial?')) {
            try {
                await axios.delete(`https://backend-gruj.onrender.com/api/historial-proyectos/${id}`);
                setHistorial(historial.filter(h => h.id_historial !== id));
            } catch (error) {
                console.error('Error al eliminar el historial:', error);
            }
        }
    };

    // Filtrar el historial según los filtros aplicados
    const historialFiltrado = historial.filter(h => {
        return (
            (filtroIdProyecto === '' || h.id_proyectos.toString().includes(filtroIdProyecto)) &&
            (filtroLiquidado === '' || h.Liquidado.toLowerCase() === filtroLiquidado.toLowerCase())
        );
    });

    return (
        <div className="gestion-historial">
            <h2>Historial de Proyectos</h2>

            <div className="acciones-filtros-historial">
                <button className="boton-agregar-historial" onClick={agregarHistorial}>Agregar Historial</button>
                <div className="filtros-historial">
                    <input
                        type="text"
                        placeholder="Filtrar por ID Proyecto"
                        value={filtroIdProyecto}
                        onChange={(e) => setFiltroIdProyecto(e.target.value)}
                    />
                    <select
                        value={filtroLiquidado}
                        onChange={(e) => setFiltroLiquidado(e.target.value)}
                    >
                        <option value="">Todos</option>
                        <option value="Sí">Liquidado</option>
                        <option value="No">No Liquidado</option>
                    </select>
                </div>
            </div>

            <table className="tabla-historial">
                <thead>
                    <tr>
                        <th>ID Proyecto</th>
                        <th>Fecha Inicio</th>
                        <th>Fecha Fin</th>
                        <th>Descripción</th>
                        <th>Fecha Liquidado</th>
                        <th>Liquidado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {historialFiltrado.map((h) => (
                        <tr key={h.id_historial}>
                            <td>{h.id_proyectos}</td>
                            <td>{new Date(h.Fecha_inicio).toLocaleDateString()}</td>
                            <td>{new Date(h.Fecha_fin).toLocaleDateString()}</td>
                            <td>{h.Descripcion_trabajo}</td>
                            <td>{new Date(h.Fecha_liquidado).toLocaleDateString()}</td>
                            <td>{h.Liquidado}</td>
                            <td>
                                <button className="boton-acciones editar" onClick={() => editarHistorial(h.id_historial)}>Editar</button>
                                <button className="boton-acciones eliminar" onClick={() => eliminarHistorial(h.id_historial)}>Eliminar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HistorialProyectos;
