import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Estilos/VistaTecnico.css';

function VistaTecnico() {
  const [foto, setFoto] = useState(null);
  const [tarea, setTarea] = useState('');
  const [fotos, setFotos] = useState([]);
  const [fotosOriginales, setFotosOriginales] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [limiteFotos] = useState(20);

  // Obtener fotos al cargar la vista
  useEffect(() => {
    axios.get('https://backend-gruj.onrender.com/api/fotos')
      .then(response => {
        setFotosOriginales(response.data);
        setFotos(response.data.slice(0, limiteFotos));
      })
      .catch(error => {
        console.error("Error al obtener las fotos:", error);
      });
  }, [limiteFotos]);

  // Validar el archivo antes de subir
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const validTypes = ['image/jpeg', 'image/png'];
    
    if (!validTypes.includes(file.type)) {
      setMensaje('Por favor, sube una imagen en formato JPEG o PNG.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // Límite de 5MB
      setMensaje('El tamaño del archivo no debe superar los 5MB.');
      return;
    }

    setFoto(file);
  };

  // Manejo de subida de la foto
  const handleUpload = () => {
    if (!foto || !tarea) {
      setMensaje('Debe seleccionar una foto y agregar una tarea.');
      return;
    }

    const formData = new FormData();
    formData.append('foto', foto);
    formData.append('tarea_foto', tarea);
    formData.append('id_usuarios', 1); // Asegúrate de tener el id del usuario correcto

    axios.post('https://backend-gruj.onrender.com/api/subir', formData)
      .then(() => {
        setMensaje('Foto subida con éxito');
        setFoto(null);
        setTarea('');
        document.getElementById('fotoForm').reset();

        // Recargar las fotos después de subir una nueva
        axios.get('https://backend-gruj.onrender.com/api/fotos')
          .then(response => {
            setFotosOriginales(response.data);
            setFotos(response.data.slice(0, limiteFotos));
          })
          .catch(error => {
            console.error("Error al recargar las fotos:", error);
          });

        setTimeout(() => {
          setMensaje('');
        }, 3000);
      })
      .catch(error => {
        console.error("Error al subir la foto:", error);
        setMensaje('Error al subir la foto');
      });
  };

  // Manejo de eliminación de una foto
  const handleDelete = (id) => {
    const confirmar = window.confirm('¿Estás seguro de que quieres eliminar esta foto?');
    if (confirmar) {
      axios.delete(`https://backend-gruj.onrender.com/api/fotos/${id}`)
        .then(() => {
          setMensaje('Foto eliminada con éxito');
          const fotosActualizadas = fotos.filter(foto => foto.id_foto !== id);
          setFotos(fotosActualizadas);
          setFotosOriginales(fotosOriginales.filter(foto => foto.id_foto !== id));

          setTimeout(() => {
            setMensaje('');
          }, 1500);
        })
        .catch(error => {
          console.error("Error al eliminar la foto:", error);
          setMensaje('Error al eliminar la foto');
        });
    }
  };

  // Filtro por tareas
  const handleFilterChange = (e) => {
    const filtro = e.target.value;
    if (filtro === '') {
      setFotos(fotosOriginales.slice(0, limiteFotos));
    } else {
      const fotosFiltradas = fotosOriginales.filter(foto => 
        foto.tarea_foto.includes(filtro)
      );
      setFotos(fotosFiltradas);
    }
  };

  return (
    <div className="vista-tecnico">
      <button className="cerrar-sesion" onClick={() => window.location.href = '/'}>Cerrar Sesión</button>

      <h1>Subir Fotografía</h1>
      <form id="fotoForm">
        <input type="file" name="foto" onChange={handleFileChange} />
        <input
          type="text"
          placeholder="Tarea relacionada"
          value={tarea}
          onChange={(e) => setTarea(e.target.value)}
        />
        <button type="button" onClick={handleUpload}>Subir Foto</button>
      </form>

      {mensaje && <p className="mensaje-exito">{mensaje}</p>}

      <h2>Imágenes Subidas</h2>
      <input
        type="text"
        placeholder="Filtrar por tarea"
        onChange={handleFilterChange}
      />

      <div className="galeria-fotos">
        {fotos.map(foto => (
          <div key={foto.id_foto} className="foto">
            <img 
              src={foto.ruta_foto} 
              alt="Fotografía" 
            />
            <p>Tarea: {foto.tarea_foto}</p>
            <a href={foto.ruta_foto} download>Descargar</a>
            <button onClick={() => handleDelete(foto.id_foto)}>Eliminar</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VistaTecnico;