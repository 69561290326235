import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import '../../Estilos/EditarHMateriales.css';

const EditarHMateriales = () => {
  const { id } = useParams(); // Obtener el id de la hoja desde los parámetros de la URL
  const navigate = useNavigate(); // Hook para redireccionar
  const [formData, setFormData] = useState({
    no_documento: "",
    tipo_documento: "",
    id_proyecto: "",
    contratista: "",
    fecha: "", // Campo para la fecha
    comentario: "",
    entrega: "",
    recibe: "",
    elaborado: "",
    materiales: [{ id_material: "", cantidad: "" }]
  });
  const [nombreProyecto, setNombreProyecto] = useState("");
  const [mensajeExito, setMensajeExito] = useState(""); // Inicialización correcta del mensaje de éxito

  useEffect(() => {
    // Obtener los detalles de la hoja de materiales para editar
    axios.get(`https://backend-gruj.onrender.com/api/h_materiales/${id}`)
      .then(response => {
        const data = response.data;
        setFormData({
          no_documento: data.no_documento,
          tipo_documento: data.tipo_documento,
          id_proyecto: data.id_proyecto,
          contratista: data.contratista,
          fecha: data.fecha ? data.fecha.split("T")[0] : "", // Formatear la fecha para el input
          comentario: data.comentario,
          entrega: data.entrega,
          recibe: data.recibe,
          elaborado: data.elaborado,
          materiales: data.materiales || [{ id_material: "", cantidad: "" }]
        });
        setNombreProyecto(data.Nombre_proyecto);
      })
      .catch(error => console.error('Error al obtener la hoja de materiales:', error));
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMaterialChange = (index, e) => {
    const newMateriales = [...formData.materiales];
    newMateriales[index][e.target.name] = e.target.value;
    setFormData({ ...formData, materiales: newMateriales });
  };

  const addMaterial = () => {
    setFormData({
      ...formData,
      materiales: [...formData.materiales, { id_material: "", cantidad: "" }]
    });
  };

  const removeMaterial = (index) => {
    const newMateriales = formData.materiales.filter((_, i) => i !== index);
    setFormData({ ...formData, materiales: newMateriales });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Actualizar los datos en el backend
    axios.put(`https://backend-gruj.onrender.com/api/h_materiales/${id}`, formData)
      .then(response => {
        setMensajeExito("Hoja de materiales actualizada con éxito");
        setTimeout(() => {
          navigate("/vista-administrativo/h-materiales");
        }, 2000);
      })
      .catch(error => console.error('Error al actualizar la hoja de materiales:', error));
  };

  return (
    <div className="container">
      <h1>Editar Hoja de Materiales</h1>
      <form onSubmit={handleSubmit}>
        <div className="group-horizontal">
          <div className="form-group">
            <label>No. Documento</label>
            <input
              type="text"
              name="no_documento"
              value={formData.no_documento}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Tipo Documento</label>
            <select
              name="tipo_documento"
              value={formData.tipo_documento}
              onChange={handleInputChange}
              required
            >
              <option value="">Seleccione...</option>
              <option value="1">Salida</option>
              <option value="2">Devolución</option>
            </select>
          </div>
        </div>

        <div className="group-horizontal">
          <div className="form-group">
            <label>Id Proyecto</label>
            <input
              type="text"
              name="id_proyecto"
              value={formData.id_proyecto}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Nombre del Proyecto</label>
            <input
              type="text"
              value={nombreProyecto || 'Error al obtener el proyecto'}
              readOnly
            />
          </div>
        </div>

        <div className="group-horizontal">
          <div className="form-group">
            <label>Contratista</label>
            <select
              name="contratista"
              value={formData.contratista}
              onChange={handleInputChange}
              required
            >
              <option value="">Seleccione un contratista...</option>
              <option value="1">LGB</option>
              <option value="2">Cable Color</option>
            </select>
          </div>
          <div className="form-group">
            <label>Fecha</label>
            <input
              type="date"
              name="fecha"
              value={formData.fecha}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="group-horizontal">
          <div className="form-group">
            <label>Entrega</label>
            <input
              type="text"
              name="entrega"
              value={formData.entrega}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Recibe</label>
            <input
              type="text"
              name="recibe"
              value={formData.recibe}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Elaborado</label>
            <input
              type="text"
              name="elaborado"
              value={formData.elaborado}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Comentario</label>
          <textarea
            name="comentario"
            value={formData.comentario}
            onChange={handleInputChange}
          />
        </div>

        <h3>Materiales</h3>
        {formData.materiales.map((material, index) => (
          <div key={index} className="material-group">
            <div className="input-with-button">
              <input
                type="text"
                name="id_material"
                placeholder="ID Material"
                value={material.id_material}
                onChange={(e) => handleMaterialChange(index, e)}
                required
              />
            </div>
            <input
              type="number"
              name="cantidad"
              placeholder="Cantidad"
              value={material.cantidad}
              onChange={(e) => handleMaterialChange(index, e)}
              required
            />
            <button type="button" onClick={() => removeMaterial(index)} className="btn btn-danger">
              Eliminar
            </button>
          </div>
        ))}

        <button type="button" onClick={addMaterial} className="btn btn-primary">
          Agregar Material
        </button>

        <button type="submit" className="btn btn-success">
          Guardar Cambios
        </button>

        {mensajeExito && (
          <div className="alert alert-success mt-3">
            {mensajeExito}
          </div>
        )}
      </form>
    </div>
  );
};

export default EditarHMateriales;
