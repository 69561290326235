import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/TipoDocumento.css';

const EditarTipoDocumento = () => {
  const { id } = useParams();
  const [tipoDocumento, setTipoDocumento] = useState({
    nombre_documento: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerTipoDocumento = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/tipos-documento/${id}`);
        setTipoDocumento(response.data);
      } catch (error) {
        console.error('Error al obtener el tipo de documento:', error);
      }
    };

    obtenerTipoDocumento();
  }, [id]);

  const handleChange = (e) => {
    setTipoDocumento({
      ...tipoDocumento,
      [e.target.name]: e.target.value
    });
  };

  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/tipos-documento/${id}`, tipoDocumento);
      setMensajeExito('Tipo de documento actualizado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/tipo-documento');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el tipo de documento:', error);
    }
  };

  return (
    <div className="gestion-tipo-documento">
      <h2>Editar Tipo de Documento</h2>
      <form onSubmit={guardarCambios} className="formulario-tipo-documento">
        <input
          type="text"
          name="nombre_documento"
          placeholder="Nombre del Documento"
          value={tipoDocumento.nombre_documento}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Cambios</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarTipoDocumento;
