import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Usuarios.css';

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerUsuarios = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/usuarios');
        setUsuarios(response.data);
      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
      }
    };
    obtenerUsuarios();
  }, []);

  const eliminarUsuario = async (id) => {
    const confirmarEliminar = window.confirm('¿Estás seguro de que quieres eliminar este usuario?');
    if (!confirmarEliminar) return;

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/usuarios/${id}`);
      setUsuarios(usuarios.filter(usuario => usuario.id_usuarios !== id));
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  const agregarUsuario = () => {
    navigate('/vista-administrativo/gestion-usuarios/agregar');
  };

  const editarUsuario = (id) => {
    navigate(`/vista-administrativo/gestion-usuarios/editar/${id}`);
  };

  return (
    <div className="gestion-usuarios">
      <h2>Gestión de Usuarios</h2>
      <button className="boton-agregar-usuario" onClick={agregarUsuario}>Agregar Usuario</button>
      <table className="tabla-usuarios">
        <thead>
          <tr>
            <th>Nombre Usuario</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario) => (
            <tr key={usuario.id_usuarios}>
              <td>{usuario.Nombre_usuarios}</td>
              <td>
                <button className="boton-acciones" onClick={() => editarUsuario(usuario.id_usuarios)}>Editar</button>
                <button className="boton-acciones eliminar" onClick={() => eliminarUsuario(usuario.id_usuarios)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Usuarios;
