// src/Vistas/Administrador/EditarRol.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/Roles.css';

const EditarRol = () => {
  const { id } = useParams(); // Obtener el ID de la URL
  const [rol, setRol] = useState({
    nombre_rol: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  // Función para obtener el rol por ID
  useEffect(() => {
    const obtenerRol = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/roles/${id}`);
        setRol(response.data);
      } catch (error) {
        console.error('Error al obtener el rol:', error);
      }
    };

    obtenerRol();
  }, [id]);

  // Función para manejar los cambios en el formulario
  const handleChange = (e) => {
    setRol({
      ...rol,
      [e.target.name]: e.target.value
    });
  };

  // Función para guardar los cambios
  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/roles/${id}`, rol);
      setMensajeExito('Rol actualizado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/roles');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el rol:', error);
    }
  };

  return (
    <div className="gestion-roles">
      <h2>Editar Rol</h2>
      <form onSubmit={guardarCambios} className="formulario-rol">
        <input
          type="text"
          name="nombre_rol"
          placeholder="Nombre del Rol"
          value={rol.nombre_rol}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Cambios</button>
      </form>
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarRol;