import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom'; // Importamos useNavigate para manejar la redirección
import { FaUser, FaCogs, FaBars, FaProjectDiagram, FaListAlt, FaFileAlt, FaWarehouse, FaChartBar, FaTag, FaUsers, FaFileInvoice, FaSignOutAlt } from 'react-icons/fa'; // FaSignOutAlt para el ícono de cerrar sesión
import '../Estilos/VistaAdministrativo.css';

const VistaAdministrativo = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate(); // Para manejar la redirección

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = () => {
    // Aquí puedes agregar lógica para el cierre de sesión (limpiar tokens, estados, etc.)
    navigate('/iniciar-sesion'); // Redireccionamos a la ruta correcta que tienes definida en App.js
  };

  return (
    <div className="admin-layout">
      {/* Menú a la izquierda */}
      <div className={`menu ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="menu-header">
          <button className="toggle-button" onClick={toggleMenu}>
            <FaBars />
          </button>
          {!isCollapsed && <span className="menu-title">J&O</span>}
        </div>
        <ul>
          <li>
            <Link to="fotografias">
              <FaFileInvoice /> {!isCollapsed && 'Fotografías'}
            </Link>
          </li>
          <li>
            <Link to="estatus-proyecto">
              <FaChartBar /> {!isCollapsed && 'Estatus Proyecto'}
            </Link>
          </li>
          <li>
            <Link to="reporte-materiales">
              <FaListAlt /> {!isCollapsed && 'Reporte Materiales'}
            </Link>
          </li>
          <li>
            <Link to="h-materiales">
              <FaWarehouse /> {!isCollapsed && 'H_Materiales'}
            </Link>
          </li>
          <li>
            <Link to="materiales">
              <FaCogs /> {!isCollapsed && 'Materiales'}
            </Link>
          </li>
          <li>
            <Link to="proyecto">
              <FaProjectDiagram /> {!isCollapsed && 'Proyecto'}
            </Link>
          </li>
          <li>
            <Link to="historial-proyectos">
              <FaListAlt /> {!isCollapsed && 'Historial Proyectos'}
            </Link>
          </li>
          <li>
            <Link to="produccion">
              <FaChartBar /> {!isCollapsed && 'Producción'}
            </Link>
          </li>
          <li>
            <Link to="descuentos">
              <FaTag /> {!isCollapsed && 'Descuentos'}
            </Link>
          </li>
          <li>
            <Link to="gestion-usuarios">
              <FaUser /> {!isCollapsed && 'Usuarios'}
            </Link>
          </li>
          <li>
            <Link to="empleados">
              <FaUsers /> {!isCollapsed && 'Empleados'}
            </Link>
          </li>
          <li>
            <Link to="cliente">
              <FaUser /> {!isCollapsed && 'Cliente'}
            </Link>
          </li>
          <li>
            <Link to="roles">
              <FaUser /> {!isCollapsed && 'Roles'}
            </Link>
          </li>
          <li>
            <Link to="tipo-documento">
              <FaFileAlt /> {!isCollapsed && 'Tipo Documento'}
            </Link>
          </li>
          {/* Botón para cerrar sesión como un item de menú */}
          <li className="logout-item">
            <button className="btn-logout" onClick={handleLogout}>
              <FaSignOutAlt /> {!isCollapsed && 'Cerrar Sesión'}
            </button>
          </li>
        </ul>
      </div>

      {/* Área de contenido a la derecha */}
      <div className={`content ${isCollapsed ? 'collapsed' : ''}`}>
        <Outlet /> {/* Aquí se renderizan las vistas hijas */}
      </div>
    </div>
  );
};

export default VistaAdministrativo;
