import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/AgregarProduccion.css';
import ModalProyectos from '../../Componentes/ModalProyectos'; // Importa el modal de proyectos
import { FaSearch } from 'react-icons/fa'; // Importamos el ícono de búsqueda

const AgregarProduccion = () => {
  const [idProyecto, setIdProyecto] = useState('');
  const [totalSinIva, setTotalSinIva] = useState('');
  const [fecha, setFecha] = useState('');
  const [mes, setMes] = useState('');
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const [mostrarModalProyectos, setMostrarModalProyectos] = useState(false);

  const navigate = useNavigate();

  // Función para abrir/cerrar el modal de proyectos
  const abrirModalProyectos = () => setMostrarModalProyectos(true);
  const cerrarModalProyectos = () => setMostrarModalProyectos(false);

  // Función para agregar la producción
  const agregarProduccion = async (e) => {
    e.preventDefault();
    setMensajeError('');
    setMensajeExito('');

    if (!idProyecto || !totalSinIva || !fecha || !mes) {
      setMensajeError('Por favor, complete todos los campos.');
      return;
    }

    try {
      const nuevaProduccion = {
        id_proyecto: idProyecto,
        total_sin_iva: totalSinIva,
        fecha,
        mes
      };

      const response = await axios.post('https://backend-gruj.onrender.com/api/produccion', nuevaProduccion);

      // Mostrar mensaje de éxito si la producción se agregó exitosamente
      setMensajeExito(response.data.message);
      setTimeout(() => {
        navigate('/vista-administrativo/produccion');
      }, 2000);
    } catch (error) {
      console.error('Error al agregar la producción', error);
      if (error.response && error.response.status === 404) {
        setMensajeError('El proyecto no existe. Por favor, agréguelo antes de continuar.');
      } else {
        setMensajeError('Hubo un error al agregar la producción');
      }
    }
  };

  return (
    <div className="agregar-produccion-container">
      <h2>Agregar Producción</h2>
      <form onSubmit={agregarProduccion} className="formulario-produccion">
        <div>
          <label>Id Proyecto:</label>
          <div className="proyecto-input-container">
            <input
              type="text"
              value={idProyecto}
              onChange={(e) => setIdProyecto(e.target.value)}
              required
            />
            <button type="button" className="icon-button" onClick={abrirModalProyectos}>
              <FaSearch size={16} />
            </button>
          </div>
        </div>
        <div>
          <label>Total Sin IVA (Q):</label>
          <input
            type="number"
            step="0.01"
            min="0"
            value={totalSinIva}
            onChange={(e) => setTotalSinIva(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Fecha:</label>
          <input
            type="date"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Mes:</label>
          <select
            value={mes}
            onChange={(e) => setMes(e.target.value)}
            required
          >
            <option value="">Seleccione un mes</option>
            <option value="ENERO">ENERO</option>
            <option value="FEBRERO">FEBRERO</option>
            <option value="MARZO">MARZO</option>
            <option value="ABRIL">ABRIL</option>
            <option value="MAYO">MAYO</option>
            <option value="JUNIO">JUNIO</option>
            <option value="JULIO">JULIO</option>
            <option value="AGOSTO">AGOSTO</option>
            <option value="SEPTIEMBRE">SEPTIEMBRE</option>
            <option value="OCTUBRE">OCTUBRE</option>
            <option value="NOVIEMBRE">NOVIEMBRE</option>
            <option value="DICIEMBRE">DICIEMBRE</option>
          </select>
        </div>
        <button type="submit">Agregar Producción</button>
      </form>

      {/* Mostrar mensaje de error o éxito */}
      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}

      {/* Modal de proyectos */}
      <ModalProyectos
        isOpen={mostrarModalProyectos}
        onClose={cerrarModalProyectos}
        seleccionarProyecto={setIdProyecto} // Permite seleccionar un proyecto
      />
    </div>
  );
};

export default AgregarProduccion;
