import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Descuentos.css';

const Descuentos = () => {  
  const [descuentos, setDescuentos] = useState([]);
  const [filtroProyecto, setFiltroProyecto] = useState('');
  const [filtroFechaInicial, setFiltroFechaInicial] = useState('');
  const [filtroFechaFinal, setFiltroFechaFinal] = useState('');
  const navigate = useNavigate();

  // Obtener la lista de descuentos desde el backend
  useEffect(() => {
    const obtenerDescuentos = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/descuentos');
        setDescuentos(response.data);
      } catch (error) {
        console.error('Error al obtener los descuentos:', error);
      }
    };

    obtenerDescuentos();
  }, []);

  // Función para eliminar un descuento
  const eliminarDescuento = async (id) => {
    const confirmarEliminar = window.confirm('¿Estás seguro de que quieres eliminar este descuento?');
    if (!confirmarEliminar) return;

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/descuentos/${id}`);
      setDescuentos(descuentos.filter(descuento => descuento.id_descuentos !== id));
    } catch (error) {
      console.error('Error al eliminar el descuento:', error);
    }
  };

  // Navegar a la vista de agregar descuento
  const agregarDescuento = () => {
    navigate('/vista-administrativo/descuentos/agregar');
  };

  // Navegar a la vista de editar descuento
  const editarDescuento = (id) => {
    navigate(`/vista-administrativo/descuentos/editar/${id}`);
  };

  // Filtrar los descuentos según los filtros aplicados
  const descuentosFiltrados = descuentos.filter(descuento => {
    const fechaDescuento = new Date(descuento.fecha);
    const fechaInicial = filtroFechaInicial ? new Date(filtroFechaInicial) : null;
    const fechaFinal = filtroFechaFinal ? new Date(filtroFechaFinal) : null;

    return (
      (!filtroProyecto || descuento.id_proyecto.toString().includes(filtroProyecto)) &&
      (!fechaInicial || fechaDescuento >= fechaInicial) &&
      (!fechaFinal || fechaDescuento <= fechaFinal)
    );
  });

  return (
    <div className="gestion-descuentos">
      <h2>Gestión de Descuentos</h2>

      <div className="acciones-descuentos">
        <button className="boton-agregar-descuento" onClick={agregarDescuento}>
          Agregar Descuento
        </button>

        <div className="filtros-descuentos">
          <input
            type="text"
            placeholder="Filtrar por ID Proyecto"
            value={filtroProyecto}
            onChange={(e) => setFiltroProyecto(e.target.value)}
          />
          <input
            type="date"
            placeholder="Fecha inicial"
            value={filtroFechaInicial}
            onChange={(e) => setFiltroFechaInicial(e.target.value)}
          />
          <input
            type="date"
            placeholder="Fecha final"
            value={filtroFechaFinal}
            onChange={(e) => setFiltroFechaFinal(e.target.value)}
          />
        </div>
      </div>

      <table className="tabla-descuentos">
        <thead>
          <tr>
            <th>ID Proyecto</th>
            <th>ID Material</th>
            <th>Nombre Material</th>
            <th>Cantidad</th>
            <th>Descuento Unitario (Q)</th>
            <th>Descuento Total (Q)</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {descuentosFiltrados.map((descuento) => (
            <tr key={descuento.id_descuentos}>
              <td>{descuento.id_proyecto}</td>
              <td>{descuento.id_materiales}</td>
              <td>{descuento.nombre_material}</td>
              <td>{descuento.cantidad}</td>
              <td>{descuento.descuento_unitario}</td>
              <td>{descuento.descuento_total}</td>
              <td>{new Date(descuento.fecha).toLocaleString()}</td>
              <td>
                <div className="contenedor-botones">
                  <button className="boton-acciones editar" onClick={() => editarDescuento(descuento.id_descuentos)}>Editar</button>
                  <button className="boton-acciones eliminar" onClick={() => eliminarDescuento(descuento.id_descuentos)}>Eliminar</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Descuentos;
