import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Inicio from './Vistas/Inicio';
import IniciarSesion from './Vistas/IniciarSesion';
import VistaAdministrativo from './Vistas/VistaAdministrativo';
import VistaTecnico from './Vistas/VistaTecnico';
import Fotografias from './Vistas/Administrador/Fotografias';
import Usuarios from './Vistas/Administrador/Usuarios';
import ReporteMateriales from './Vistas/Administrador/ReporteMateriales';
import AgregarReporteMateriales from './Vistas/Administrador/AgregarReporteMateriales';
import EditarReporteMateriales from './Vistas/Administrador/EditarReporteMateriales'; 
import EstatusProyecto from './Vistas/Administrador/EstatusProyecto';
import HMateriales from './Vistas/Administrador/HMateriales';
import AgregarHMateriales from './Vistas/Administrador/AgregarHMateriales'; 
import EditarHMateriales from './Vistas/Administrador/EditarHMateriales';   
import Produccion from './Vistas/Administrador/Produccion';
import AgregarProduccion from './Vistas/Administrador/AgregarProduccion';
import EditarProduccion from './Vistas/Administrador/EditarProduccion';
import Descuentos from './Vistas/Administrador/Descuentos';
import AgregarDescuento from './Vistas/Administrador/AgregarDescuentos';
import EditarDescuento from './Vistas/Administrador/EditarDescuentos';
import Empleados from './Vistas/Administrador/Empleados';
import Cliente from './Vistas/Administrador/Cliente';
import AgregarCliente from './Vistas/Administrador/AgregarCliente';
import EditarCliente from './Vistas/Administrador/EditarCliente';
import Roles from './Vistas/Administrador/Roles';
import AgregarRol from './Vistas/Administrador/AgregarRol';
import EditarRol from './Vistas/Administrador/EditarRol';
import TipoDocumento from './Vistas/Administrador/TipoDocumento';
import AgregarTipoDocumento from './Vistas/Administrador/AgregarTipoDocumento';
import EditarTipoDocumento from './Vistas/Administrador/EditarTipoDocumento';
import AgregarEmpleado from './Vistas/Administrador/AgregarEmpleado';
import EditarEmpleado from './Vistas/Administrador/EditarEmpleado';
import AgregarUsuario from './Vistas/Administrador/AgregarUsuario';
import EditarUsuario from './Vistas/Administrador/EditarUsuario';
import Proyecto from './Vistas/Administrador/Proyecto';
import AgregarProyecto from './Vistas/Administrador/AgregarProyecto';
import EditarProyecto from './Vistas/Administrador/EditarProyecto';
import Materiales from './Vistas/Administrador/Materiales';
import AgregarMaterial from './Vistas/Administrador/AgregarMaterial';
import EditarMaterial from './Vistas/Administrador/EditarMaterial';
import HistorialProyectos from './Vistas/Administrador/HistorialProyectos';
import AgregarHistorialProyectos from './Vistas/Administrador/AgregarHistorialProyectos';
import EditarHistorialProyectos from './Vistas/Administrador/EditarHistorialProyectos';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/iniciar-sesion" element={<IniciarSesion />} />
        <Route path="/vista-tecnico" element={<VistaTecnico />} />

        <Route path="/vista-administrativo" element={<VistaAdministrativo />}>
          <Route path="fotografias" element={<Fotografias />} />
          <Route path="estatus-proyecto" element={<EstatusProyecto />} />
          <Route path="materiales" element={<Materiales />} />
          <Route path="proyecto" element={<Proyecto />} />
          <Route path="descuentos" element={<Descuentos />} />
          <Route path="descuentos/agregar" element={<AgregarDescuento />} />
          <Route path="descuentos/editar/:id" element={<EditarDescuento />} />
          <Route path="/vista-administrativo/fotografias" element={<Fotografias />} />

          <Route path="gestion-usuarios" element={<Usuarios />} />
          <Route path="gestion-usuarios/agregar" element={<AgregarUsuario />} />
          <Route path="gestion-usuarios/editar/:id" element={<EditarUsuario />} />

          <Route path="cliente" element={<Cliente />} />
          <Route path="cliente/agregar" element={<AgregarCliente />} />
          <Route path="cliente/editar/:id" element={<EditarCliente />} />

          <Route path="roles" element={<Roles />} />
          <Route path="roles/agregar" element={<AgregarRol />} />
          <Route path="roles/editar/:id" element={<EditarRol />} />

          <Route path="tipo-documento" element={<TipoDocumento />} />
          <Route path="tipo-documento/agregar" element={<AgregarTipoDocumento />} />
          <Route path="tipo-documento/editar/:id" element={<EditarTipoDocumento />} />

          <Route path="empleados" element={<Empleados />} />
          <Route path="empleados/agregar" element={<AgregarEmpleado />} />
          <Route path="empleados/editar/:id" element={<EditarEmpleado />} />

          <Route path="proyecto/agregar" element={<AgregarProyecto />} />
          <Route path="proyecto/editar/:id" element={<EditarProyecto />} />

          <Route path="materiales/agregar" element={<AgregarMaterial />} />
          <Route path="materiales/editar/:id" element={<EditarMaterial />} />

          <Route path="historial-proyectos" element={<HistorialProyectos />} />
          <Route path="historial-proyectos/agregar" element={<AgregarHistorialProyectos />} />
          <Route path="historial-proyectos/editar/:id" element={<EditarHistorialProyectos />} />

          <Route path="produccion" element={<Produccion />} />
          <Route path="produccion/agregar" element={<AgregarProduccion />} />
          <Route path="produccion/editar/:id" element={<EditarProduccion />} />

          <Route path="reporte-materiales" element={<ReporteMateriales />} />
          <Route path="reporte-materiales/agregar" element={<AgregarReporteMateriales />} />
          <Route path="reporte-materiales/editar/:id" element={<EditarReporteMateriales />} /> 

          <Route path="h-materiales" element={<HMateriales />} />
          <Route path="h-materiales/agregar" element={<AgregarHMateriales />} />
          <Route path="h-materiales/editar/:id" element={<EditarHMateriales />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

