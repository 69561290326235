import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Para redireccionar
import '../../Estilos/AgregarReporteMateriales.css';
import ModalMateriales from '../../Componentes/ModalMateriales'; 
import ModalProyectos from '../../Componentes/ModalProyectos'; 

const AgregarReporteMateriales = () => {
  const [idProyecto, setIdProyecto] = useState('');
  const [nombreProyecto, setNombreProyecto] = useState('');
  const [nombreTecnico, setNombreTecnico] = useState('');
  const [materiales, setMateriales] = useState([{ id_material: '', cantidad_usada: '', nombre_material: '' }]);
  const [isModalMaterialesOpen, setIsModalMaterialesOpen] = useState(false);
  const [isModalProyectosOpen, setIsModalProyectosOpen] = useState(false);
  const [mensajeExito, setMensajeExito] = useState(''); // Para mostrar el mensaje de éxito
  const navigate = useNavigate(); // Hook para redireccionar

  // Función para obtener el nombre del proyecto
  const obtenerNombreProyecto = async (id) => {
    if (!id) return;
    try {
      const response = await axios.get(`https://backend-gruj.onrender.com/api/proyectos/${id}`);
      setNombreProyecto(response.data.Nombre_proyecto);
    } catch (error) {
      console.error('Error al obtener el nombre del proyecto:', error);
      setNombreProyecto('Proyecto no encontrado');
    }
  };

  // Función para obtener el nombre del material
  const obtenerNombreMaterial = async (id, index) => {
    if (!id) {
      const nuevosMateriales = [...materiales];
      nuevosMateriales[index].nombre_material = '';
      setMateriales(nuevosMateriales);
      return;
    }

    try {
      const response = await axios.get(`https://backend-gruj.onrender.com/api/materiales/${id}`);
      const nuevosMateriales = [...materiales];
      nuevosMateriales[index].nombre_material = response.data?.nombre_material || 'Material no encontrado';
      setMateriales(nuevosMateriales);
    } catch (error) {
      console.error('Error al obtener el nombre del material:', error);
      const nuevosMateriales = [...materiales];
      nuevosMateriales[index].nombre_material = 'Material no encontrado';
      setMateriales(nuevosMateriales);
    }
  };

  // Manejar cambio del ID Proyecto
  const manejarCambioIdProyecto = (e) => {
    const id = e.target.value;
    setIdProyecto(id);
    if (id) {
      obtenerNombreProyecto(id);
    } else {
      setNombreProyecto('');
    }
  };

  const agregarMaterial = () => {
    setMateriales([...materiales, { id_material: '', cantidad_usada: '', nombre_material: '' }]);
  };

  const eliminarMaterial = (index) => {
    const nuevosMateriales = [...materiales];
    nuevosMateriales.splice(index, 1);
    setMateriales(nuevosMateriales);
  };

  const manejarCambioMaterial = (index, e) => {
    const { name, value } = e.target;
    const nuevosMateriales = [...materiales];
    nuevosMateriales[index][name] = value;

    if (name === 'id_material') {
      obtenerNombreMaterial(value, index);
    }

    setMateriales(nuevosMateriales);
  };

  const manejarEnvioFormulario = (e) => {
    e.preventDefault();
    const data = {
      id_proyecto: idProyecto,
      nombre_tecnico: nombreTecnico,
      materiales,
    };

    axios.post('https://backend-gruj.onrender.com/api/reporte-materiales', data)
      .then(() => {
        // Mostrar el mensaje de éxito
        setMensajeExito('Reporte agregado exitosamente');
        
        // Esperar 2 segundos antes de redirigir
        setTimeout(() => {
          navigate('/vista-administrativo/reporte-materiales'); // Redirige a ReporteMateriales.js
        }, 2000); // 2 segundos
      })
      .catch(error => {
        console.error('Error al agregar el reporte de materiales:', error);
        alert('Hubo un error al agregar el reporte');
      });
  };

  const abrirModalMateriales = () => {
    setIsModalMaterialesOpen(true);
  };

  const cerrarModalMateriales = () => {
    setIsModalMaterialesOpen(false);
  };

  const abrirModalProyectos = () => {
    setIsModalProyectosOpen(true);
  };

  const cerrarModalProyectos = () => {
    setIsModalProyectosOpen(false);
  };

  return (
    <div className="agregar-reporte-materiales-container">
      <h2>Agregar Reporte de Materiales</h2>
      {mensajeExito && <div className="mensaje-exito">{mensajeExito}</div>} {/* Mostrar mensaje de éxito */}
      <form onSubmit={manejarEnvioFormulario}>
        <div className="proyecto-row">
          <label>ID Proyecto:</label>
          <input
            type="text"
            value={idProyecto}
            onChange={manejarCambioIdProyecto}
            required
          />
          {nombreProyecto && <span className="nombre-proyecto">{nombreProyecto}</span>}
          <button type="button" className="btn-buscar-proyecto" onClick={abrirModalProyectos}>
            Buscar Proyecto
          </button>
        </div>

        <div className="tecnico-row">
          <label>Nombre Técnico:</label>
          <input
            type="text"
            value={nombreTecnico}
            onChange={(e) => setNombreTecnico(e.target.value)}
            required
          />
        </div>

        {materiales.map((material, index) => (
          <div key={index} className="material-row">
            <input
              type="text"
              name="id_material"
              placeholder="ID Material"
              value={material.id_material}
              onChange={(e) => manejarCambioMaterial(index, e)}
              required
            />
            {material.nombre_material && <span className="nombre-material">{material.nombre_material}</span>}
            <button type="button" className="btn-buscar-material" onClick={abrirModalMateriales}>
              Buscar Material
            </button>
            <input
              type="number"
              name="cantidad_usada"
              placeholder="Cantidad Usada"
              value={material.cantidad_usada}
              onChange={(e) => manejarCambioMaterial(index, e)}
              required
            />
            <button type="button" onClick={() => eliminarMaterial(index)}>
              Eliminar
            </button>
          </div>
        ))}

        <button type="button" className="btn-agregar-material" onClick={agregarMaterial}>
          Agregar Material
        </button>
        <button type="submit" className="btn-agregar-reporte">Agregar Reporte</button>
      </form>

      {/* Renderizamos los Modales */}
      <ModalMateriales isOpen={isModalMaterialesOpen} onClose={cerrarModalMateriales} />
      <ModalProyectos isOpen={isModalProyectosOpen} onClose={cerrarModalProyectos} />
    </div>
  );
};

export default AgregarReporteMateriales;
