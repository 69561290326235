import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/AgregarMaterial.css';

const AgregarMaterial = () => {
  const [material, setMaterial] = useState({
    id_materiales: '',
    nombre_material: '',
    unidad_medida: '',
    id_clientes: ''
  });
  const [clientes, setClientes] = useState([]); // Lista de clientes
  const [unidadesMedida, setUnidadesMedida] = useState(['LB', 'MTS', 'UNI']); // Lista de unidades de medida
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  // Obtener la lista de clientes desde el backend
  useEffect(() => {
    const obtenerClientes = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/clientes'); // Asegúrate de que esta ruta obtenga los clientes
        setClientes(response.data);
      } catch (error) {
        console.error('Error al obtener los clientes:', error);
      }
    };

    obtenerClientes();
  }, []);

  const handleChange = (e) => {
    setMaterial({
      ...material,
      [e.target.name]: e.target.value
    });
  };

  const agregarMaterial = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-gruj.onrender.com/api/materiales', material);
      setMensajeExito('Material agregado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/materiales');
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setMensajeError(error.response.data.error); // Mostrar mensaje de error si el código ya existe
      } else {
        console.error('Error al agregar el material:', error);
      }
    }
  };

  return (
    <div className="gestion-materiales">
      <h2>Agregar Nuevo Material</h2>
      <form onSubmit={agregarMaterial} className="formulario-material">
        <label htmlFor="id_materiales">Código del Material</label>
        <input
          type="text"
          name="id_materiales"
          placeholder="Código del Material"
          value={material.id_materiales}
          onChange={handleChange}
          required
        />
        
        <label htmlFor="nombre_material">Nombre del Material</label>
        <input
          type="text"
          name="nombre_material"
          placeholder="Nombre del Material"
          value={material.nombre_material}
          onChange={handleChange}
          required
        />

        <label htmlFor="unidad_medida">Unidad de Medida</label>
        <select
          name="unidad_medida"
          value={material.unidad_medida}
          onChange={handleChange}
          required
        >
          <option value="">Selecciona una unidad de medida</option>
          {unidadesMedida.map((unidad) => (
            <option key={unidad} value={unidad}>
              {unidad}
            </option>
          ))}
        </select>

        <label htmlFor="id_clientes">Cliente</label>
        <select
          name="id_clientes"
          value={material.id_clientes}
          onChange={handleChange}
          required
        >
          <option value="">Selecciona un cliente</option>
          {clientes.map((cliente) => (
            <option key={cliente.id_cliente} value={cliente.id_cliente}>
              {cliente.nombre_cliente}
            </option>
          ))}
        </select>

        <button type="submit">Guardar Material</button>
      </form>

      {/* Mostrar mensaje de error o éxito */}
      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default AgregarMaterial;
