import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/AgregarDescuentos.css';
import { FaSearch } from 'react-icons/fa';
import ModalMateriales from '../../Componentes/ModalMateriales';
import ModalProyectos from '../../Componentes/ModalProyectos';

const AgregarDescuento = () => {
  const [idProyecto, setIdProyecto] = useState('');
  const [nombreProyecto, setNombreProyecto] = useState('');
  const [idMateriales, setIdMateriales] = useState('');
  const [nombreMaterial, setNombreMaterial] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [descuentoUnitario, setDescuentoUnitario] = useState('');
  const [fecha, setFecha] = useState('');
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');

  // Estado para los modales
  const [mostrarModalMateriales, setMostrarModalMateriales] = useState(false);
  const [mostrarModalProyectos, setMostrarModalProyectos] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (idProyecto) {
      validarProyecto(); // Valida el proyecto si el ID está presente
    }
    if (idMateriales) {
      validarMaterial(); // Valida el material si el ID está presente
    }
  }, [idProyecto, idMateriales]);

  const validarProyecto = async () => {
    try {
      const response = await axios.get(`https://backend-gruj.onrender.com/api/proyectos/${idProyecto}`);
      if (response.data) {
        setNombreProyecto(response.data.Nombre_proyecto);
        setMensajeError('');
      } else {
        setNombreProyecto('');
        setMensajeError('El proyecto no existe');
      }
    } catch (error) {
      console.error('Error al verificar el proyecto:', error);
      setNombreProyecto('');
      setMensajeError('Error al verificar el proyecto.');
    }
  };

  const validarMaterial = async () => {
    try {
      const response = await axios.get(`https://backend-gruj.onrender.com/api/materiales/${idMateriales}`);
      if (response.data) {
        setNombreMaterial(response.data.nombre_material);
        setMensajeError('');
      } else {
        setNombreMaterial('');
        setMensajeError('El material no existe');
      }
    } catch (error) {
      console.error('Error al verificar el material:', error);
      setNombreMaterial('');
      setMensajeError('Error al verificar el material.');
    }
  };

  const agregarDescuento = async (e) => {
    e.preventDefault();
    setMensajeError('');
    setMensajeExito('');

    // Validación en el frontend antes de enviar los datos
    if (!nombreProyecto || !nombreMaterial) {
      setMensajeError('Asegúrate de que los IDs de Proyecto y Material sean válidos.');
      return;
    }

    // Validación de campos obligatorios
    if (!cantidad || !descuentoUnitario || !fecha) {
      setMensajeError('Todos los campos son obligatorios.');
      return;
    }

    if (cantidad <= 0 || descuentoUnitario <= 0) {
      setMensajeError('Cantidad y Descuento Unitario deben ser mayores a 0.');
      return;
    }

    // Validación en el backend
    try {
      const nuevoDescuento = {
        id_proyecto: idProyecto,
        id_materiales: idMateriales,
        cantidad,
        descuento_unitario: descuentoUnitario,
        fecha
      };

      await axios.post('https://backend-gruj.onrender.com/api/descuentos', nuevoDescuento);
      setMensajeExito('Descuento agregado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/descuentos');
      }, 2000);
    } catch (error) {
      console.error('Error al agregar el descuento:', error);
      setMensajeError('Hubo un error al agregar el descuento.');
    }
  };

  return (
    <div className="agregar-descuento-container">
      <h2>Agregar Descuento</h2>
      <form onSubmit={agregarDescuento} className="formulario-descuento">
        <div>
          <label>Fecha:</label>
          <input
            type="date"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Id Proyecto:</label>
          <div className="proyecto-input-container">
            <input
              type="text"
              value={idProyecto}
              onChange={(e) => setIdProyecto(e.target.value)}
              required
            />
            <span>{nombreProyecto ? nombreProyecto : mensajeError}</span>
            <button type="button" className="icon-button" onClick={() => setMostrarModalProyectos(true)}>
              <FaSearch size={16} />
            </button>
          </div>
        </div>

        <div>
          <label>Id Material:</label>
          <div className="material-input-container">
            <input
              type="text"
              value={idMateriales}
              onChange={(e) => setIdMateriales(e.target.value)}
              required
            />
            <span>{nombreMaterial ? nombreMaterial : mensajeError}</span>
            <button type="button" className="icon-button" onClick={() => setMostrarModalMateriales(true)}>
              <FaSearch size={16} />
            </button>
          </div>
        </div>

        <div>
          <label>Cantidad:</label>
          <input
            type="number"
            min="1"
            value={cantidad}
            onChange={(e) => setCantidad(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Descuento Unitario (Q):</label>
          <input
            type="number"
            step="0.01"
            min="0"
            value={descuentoUnitario}
            onChange={(e) => setDescuentoUnitario(e.target.value)}
            required
          />
        </div>

        <button type="submit">Agregar Descuento</button>
      </form>

      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}

      <ModalMateriales
        isOpen={mostrarModalMateriales}
        onClose={() => setMostrarModalMateriales(false)}
        materiales={[]}
      />

      <ModalProyectos
        isOpen={mostrarModalProyectos}
        onClose={() => setMostrarModalProyectos(false)}
        proyectos={[]}
      />
    </div>
  );
};

export default AgregarDescuento;
