import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/Cliente.css';

const EditarCliente = () => {
  const { id } = useParams(); // Obtener el ID de la URL
  const [cliente, setCliente] = useState({
    nombre_cliente: '',
    correo_cliente: '',
    telefono: '',
    direccion: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  // Función para obtener el cliente específico por ID
  useEffect(() => {
    const obtenerCliente = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/clientes/${id}`);
        setCliente(response.data); // Llenar el formulario con los datos obtenidos
      } catch (error) {
        console.error('Error al obtener los datos del cliente:', error);
      }
    };

    obtenerCliente(); // Llamar a la función al montar el componente
  }, [id]);

  // Manejar los cambios en el formulario
  const handleChange = (e) => {
    setCliente({
      ...cliente,
      [e.target.name]: e.target.value
    });
  };

  // Función para guardar los cambios del cliente editado
  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/clientes/${id}`, cliente);
      setMensajeExito('Cliente actualizado exitosamente');
      // Redirigir a la lista de clientes después de 2 segundos
      setTimeout(() => {
        navigate('/vista-administrativo/cliente');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el cliente:', error);
    }
  };

  return (
    <div className="gestion-clientes">
      <h2>Editar Cliente</h2>
      <form onSubmit={guardarCambios} className="formulario-cliente">
        <input
          type="text"
          name="nombre_cliente"
          placeholder="Nombre"
          value={cliente.nombre_cliente} // Pre-llenar el valor
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="correo_cliente"
          placeholder="Email"
          value={cliente.correo_cliente} // Pre-llenar el valor
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="telefono"
          placeholder="Teléfono"
          value={cliente.telefono} // Pre-llenar el valor
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="direccion"
          placeholder="Dirección"
          value={cliente.direccion} // Pre-llenar el valor
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Cambios</button>
      </form>

      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarCliente;
