import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../../Estilos/EditarProduccion.css';
import ModalProyectos from '../../Componentes/ModalProyectos'; // Importa el modal de proyectos
import { FaSearch } from 'react-icons/fa'; // Importamos el ícono de búsqueda

const EditarProduccion = () => {
  const { id } = useParams(); // Obtenemos el id de la producción a editar
  const [idProyecto, setIdProyecto] = useState('');
  const [totalSinIva, setTotalSinIva] = useState('');
  const [fecha, setFecha] = useState('');
  const [mes, setMes] = useState('');
  const [iva, setIva] = useState('');
  const [sumaTotal, setSumaTotal] = useState('');
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const [mostrarModalProyectos, setMostrarModalProyectos] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Obtener la información de la producción existente
    const obtenerProduccion = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/produccion/${id}`);
        const produccion = response.data;
        setIdProyecto(produccion.id_proyectos); // Asegúrate de usar el nombre correcto
        setTotalSinIva(produccion.total_sin_iva);
        setFecha(produccion.fecha.split('T')[0]); // Ajustar formato de fecha
        setMes(produccion.mes);
        setIva(produccion.iva);
        setSumaTotal(produccion.suma_total);
      } catch (error) {
        console.error('Error al obtener la producción', error);
        setMensajeError('Hubo un error al obtener la producción');
      }
    };

    obtenerProduccion();
  }, [id]);

  // Función para abrir/cerrar el modal de proyectos
  const abrirModalProyectos = () => setMostrarModalProyectos(true);
  const cerrarModalProyectos = () => setMostrarModalProyectos(false);

  // Manejo del cambio de total sin IVA, calculando IVA y suma total automáticamente
  const manejarCambioTotalSinIva = (e) => {
    const valor = parseFloat(e.target.value);
    setTotalSinIva(valor);
    const ivaCalculado = valor * 0.12; // IVA del 12%
    setIva(ivaCalculado.toFixed(2)); // Formateamos a 2 decimales
    setSumaTotal((valor + ivaCalculado).toFixed(2)); // Formateamos a 2 decimales
  };

  // Función para actualizar la producción
  const actualizarProduccion = async (e) => {
    e.preventDefault();
    setMensajeError('');
    setMensajeExito('');

    if (!idProyecto || !totalSinIva || !fecha || !mes) {
      setMensajeError('Por favor, complete todos los campos.');
      return;
    }

    try {
      const produccionActualizada = {
        id_proyecto: idProyecto,
        total_sin_iva: totalSinIva,
        iva: parseFloat(iva),
        suma_total: parseFloat(sumaTotal),
        fecha,
        mes
      };

      await axios.put(`https://backend-gruj.onrender.com/api/produccion/${id}`, produccionActualizada);
      setMensajeExito('Producción actualizada exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/produccion');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar la producción', error);
      setMensajeError('Hubo un error al actualizar la producción');
    }
  };

  return (
    <div className="editar-produccion-container">
      <h2>Editar Producción</h2>
      <form onSubmit={actualizarProduccion} className="formulario-produccion">
        <div>
          <label>Id Proyecto:</label>
          <div className="proyecto-input-container">
            <input
              type="text"
              value={idProyecto}
              onChange={(e) => setIdProyecto(e.target.value)}
              required
            />
            <button type="button" className="icon-button" onClick={abrirModalProyectos}>
              <FaSearch size={16} />
            </button>
          </div>
        </div>
        <div>
          <label>Total Sin IVA (Q):</label>
          <input
            type="number"
            step="0.01"
            min="0"
            value={totalSinIva}
            onChange={manejarCambioTotalSinIva}
            required
          />
        </div>
        <div>
          <label>IVA (Q):</label>
          <input
            type="text"
            value={iva}
            readOnly
          />
        </div>
        <div>
          <label>Suma Total (Q):</label>
          <input
            type="text"
            value={sumaTotal}
            readOnly
          />
        </div>
        <div>
          <label>Fecha:</label>
          <input
            type="date"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Mes:</label>
          <select
            value={mes}
            onChange={(e) => setMes(e.target.value)}
            required
          >
            <option value="">Seleccione un mes</option>
            <option value="ENERO">ENERO</option>
            <option value="FEBRERO">FEBRERO</option>
            <option value="MARZO">MARZO</option>
            <option value="ABRIL">ABRIL</option>
            <option value="MAYO">MAYO</option>
            <option value="JUNIO">JUNIO</option>
            <option value="JULIO">JULIO</option>
            <option value="AGOSTO">AGOSTO</option>
            <option value="SEPTIEMBRE">SEPTIEMBRE</option>
            <option value="OCTUBRE">OCTUBRE</option>
            <option value="NOVIEMBRE">NOVIEMBRE</option>
            <option value="DICIEMBRE">DICIEMBRE</option>
          </select>
        </div>
        <button type="submit">Actualizar Producción</button>
      </form>

      {/* Mostrar mensaje de error o éxito */}
      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}

      {/* Modal de proyectos */}
      <ModalProyectos
        isOpen={mostrarModalProyectos}
        onClose={cerrarModalProyectos}
        seleccionarProyecto={setIdProyecto} // Permite seleccionar un proyecto
      />
    </div>
  );
};

export default EditarProduccion;
