import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/EditarMaterial.css';

const EditarMaterial = () => {
  const { id } = useParams(); // Obtener el ID del material desde la URL
  const [material, setMaterial] = useState({
    id_materiales: '',
    nombre_material: '',
    unidad_medida: '',
    id_clientes: ''
  });
  const [clientes, setClientes] = useState([]); // Lista de clientes
  const [unidadesMedida] = useState(['LB', 'MTS', 'UNI']); // Lista de unidades de medida (sin setUnidadesMedida)
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  // Obtener el material a editar desde el backend
  useEffect(() => {
    const obtenerMaterial = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/materiales/${id}`);
        setMaterial(response.data);
      } catch (error) {
        console.error('Error al obtener el material:', error);
        setMensajeError('Error al cargar el material.');
      }
    };

    const obtenerClientes = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/clientes');
        setClientes(response.data);
      } catch (error) {
        console.error('Error al obtener los clientes:', error);
      }
    };

    obtenerMaterial();
    obtenerClientes();
  }, [id]);

  const handleChange = (e) => {
    setMaterial({
      ...material,
      [e.target.name]: e.target.value
    });
  };

  const actualizarMaterial = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/materiales/${id}`, material);
      setMensajeExito('Material actualizado exitosamente');
      setTimeout(() => {
        navigate('/vista-administrativo/materiales');
      }, 2000);
    } catch (error) {
      console.error('Error al actualizar el material:', error);
      setMensajeError('Error al actualizar el material.');
    }
  };

  return (
    <div className="gestion-materiales">
      <h2>Editar Material</h2>
      <form onSubmit={actualizarMaterial} className="formulario-material">
        
        {/* Mostrar el código de material solo como referencia */}
        <label htmlFor="id_materiales">Código del Material</label>
        <input
          type="text"
          name="id_materiales"
          value={material.id_materiales}
          readOnly
          disabled
        />
        
        <label htmlFor="nombre_material">Nombre del Material</label>
        <input
          type="text"
          name="nombre_material"
          value={material.nombre_material}
          onChange={handleChange}
          required
        />

        <label htmlFor="unidad_medida">Unidad de Medida</label>
        <select
          name="unidad_medida"
          value={material.unidad_medida}
          onChange={handleChange}
          required
        >
          <option value="">Selecciona una unidad de medida</option>
          {unidadesMedida.map((unidad) => (
            <option key={unidad} value={unidad}>
              {unidad}
            </option>
          ))}
        </select>

        <label htmlFor="id_clientes">Cliente</label>
        <select
          name="id_clientes"
          value={material.id_clientes}
          onChange={handleChange}
          required
        >
          <option value="">Selecciona un cliente</option>
          {clientes.map((cliente) => (
            <option key={cliente.id_cliente} value={cliente.id_cliente}>
              {cliente.nombre_cliente}
            </option>
          ))}
        </select>

        <button type="submit">Guardar Cambios</button>
      </form>

      {/* Mostrar mensaje de error o éxito */}
      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default EditarMaterial;
