import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/AgregarHistorialProyectos.css';

const AgregarHistorialProyectos = () => {
  const [id_proyectos, setIdProyectos] = useState('');
  const [Fecha_inicio, setFechaInicio] = useState('');
  const [Fecha_fin, setFechaFin] = useState('');
  const [Descripcion_trabajo, setDescripcionTrabajo] = useState('');
  const [Fecha_liquidado, setFechaLiquidado] = useState('');
  const [Liquidado, setLiquidado] = useState(false);
  const [mensajeError, setMensajeError] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate();

  const manejarSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Verificar si el proyecto existe
      await axios.get(`https://backend-gruj.onrender.com/api/proyectos/${id_proyectos}`);
    } catch (error) {
      // Manejar el error si el proyecto no existe
      if (error.response && error.response.status === 404) {
        setMensajeError('El ID del proyecto no existe.');
      } else {
        setMensajeError('Ocurrió un error al verificar el proyecto.');
      }
      return;
    }
  
    // Verificar si ya existe un historial para este proyecto
    try {
      const respuestaHistorial = await axios.get(`https://backend-gruj.onrender.com/api/historial-proyectos/proyecto/${id_proyectos}`);
      if (respuestaHistorial.data && respuestaHistorial.data.existe) {
        setMensajeError('Ya existe un historial para este proyecto.');
        return;
      }
  
      // Si todo es correcto, agregar el historial
      await axios.post('https://backend-gruj.onrender.com/api/historial-proyectos', {
        id_proyectos,
        Fecha_inicio,
        Fecha_fin,
        Descripcion_trabajo,
        Fecha_liquidado,
        Liquidado: Liquidado ? 'Sí' : 'No'
      });
      setMensajeExito('Historial agregado correctamente.');
      setTimeout(() => {
        navigate('/vista-administrativo/historial-proyectos');
      }, 2000);
  
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setMensajeError('Ocurrió un error al guardar el historial.');
    }
  };
  

  return (
    <div className="agregar-historial">
      <h2>Agregar Nuevo Historial de Proyecto</h2>
      {mensajeError && <div className="mensaje-error">{mensajeError}</div>}
      {mensajeExito && <div className="mensaje-exito">{mensajeExito}</div>}
      <form onSubmit={manejarSubmit}>
        <div className="form-group">
          <label>ID Proyecto</label>
          <input
            type="text"
            value={id_proyectos}
            onChange={(e) => setIdProyectos(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Fecha de Inicio</label>
          <input
            type="date"
            value={Fecha_inicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Fecha de Fin</label>
          <input
            type="date"
            value={Fecha_fin}
            onChange={(e) => setFechaFin(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Descripción del Trabajo</label>
          <input
            type="text"
            value={Descripcion_trabajo}
            onChange={(e) => setDescripcionTrabajo(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Fecha Liquidado</label>
          <input
            type="date"
            value={Fecha_liquidado}
            onChange={(e) => setFechaLiquidado(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Liquidado</label>
          <input
            type="checkbox"
            checked={Liquidado}
            onChange={(e) => setLiquidado(e.target.checked)}
          />
        </div>

        <button type="submit" className="boton-guardar">
          Guardar Historial
        </button>
      </form>
    </div>
  );
};

export default AgregarHistorialProyectos;
