import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Roles.css'; // Asegúrate de que los estilos se carguen correctamente

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  // Obtener la lista de roles desde el backend
  useEffect(() => {
    const obtenerRoles = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/roles');
        setRoles(response.data);
      } catch (error) {
        console.error('Error al obtener los roles:', error);
      }
    };

    obtenerRoles();
  }, []);

  // Función para eliminar un rol
  const eliminarRol = async (id) => {
    // Confirmación antes de eliminar
    const confirmarEliminar = window.confirm('¿Estás seguro de que quieres eliminar este rol?');
    if (!confirmarEliminar) {
      return; // Si cancela, no se ejecuta el código de eliminación
    }

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/roles/${id}`);
      setRoles(roles.filter(rol => rol.id_roles !== id)); // Actualizar la lista de roles
    } catch (error) {
      console.error('Error al eliminar el rol:', error);
    }
  };

  // Navegar a la vista de agregar rol
  const agregarRol = () => {
    navigate('/vista-administrativo/roles/agregar');
  };

  // Navegar a la vista de editar rol
  const editarRol = (id) => {
    navigate(`/vista-administrativo/roles/editar/${id}`);
  };

  return (
    <div className="gestion-roles">
      <h2>Gestión de Roles</h2>
      <button className="boton-agregar-rol" onClick={agregarRol}>Agregar Rol</button>

      <table className="tabla-roles">
        <thead>
          <tr>
            <th>Nombre Rol</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((rol) => (
            <tr key={rol.id_roles}>
              <td>{rol.nombre_rol}</td>
              <td>
                <button className="boton-editar" onClick={() => editarRol(rol.id_roles)}>Editar</button>
                <button className="boton-eliminar" onClick={() => eliminarRol(rol.id_roles)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Roles;
