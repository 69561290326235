import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Materiales.css';

const Materiales = () => {
  const [materiales, setMateriales] = useState([]);
  const [filtroCodigo, setFiltroCodigo] = useState('');
  const [filtroNombre, setFiltroNombre] = useState('');
  const navigate = useNavigate();

  // Obtener la lista de materiales desde el backend
  useEffect(() => {
    const obtenerMateriales = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/materiales');
        setMateriales(response.data);
      } catch (error) {
        console.error('Error al obtener los materiales:', error);
      }
    };

    obtenerMateriales();
  }, []);

  // Función para eliminar un material
  const eliminarMaterial = async (id) => {
    const confirmarEliminar = window.confirm('¿Estás seguro de que quieres eliminar este material?');
    if (!confirmarEliminar) return;

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/materiales/${id}`);
      setMateriales(materiales.filter(material => material.id_materiales !== id));
    } catch (error) {
      console.error('Error al eliminar el material:', error);
    }
  };

  // Navegar a la vista de agregar material
  const agregarMaterial = () => {
    navigate('/vista-administrativo/materiales/agregar');
  };

  // Navegar a la vista de editar material
  const editarMaterial = (id) => {
    navigate(`/vista-administrativo/materiales/editar/${id}`);
  };

  // Filtrar materiales por código o nombre
  const materialesFiltrados = materiales.filter((material) => {
    return (
      material.id_materiales.includes(filtroCodigo) &&
      material.nombre_material.toLowerCase().includes(filtroNombre.toLowerCase())
    );
  });

  return (
    <div className="gestion-materiales">
      <h2>Gestión de Materiales</h2>

      <div className="acciones-materiales">
        <button className="boton-agregar-material" onClick={agregarMaterial}>Agregar Material</button>
        <div className="filtros-materiales">
          <input
            type="text"
            placeholder="Filtrar por código"
            value={filtroCodigo}
            onChange={(e) => setFiltroCodigo(e.target.value)}
            className="filtro-codigo"
          />
          <input
            type="text"
            placeholder="Filtrar por nombre"
            value={filtroNombre}
            onChange={(e) => setFiltroNombre(e.target.value)}
            className="filtro-nombre"
          />
        </div>
      </div>

      <table className="tabla-materiales">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nombre del Material</th>
            <th>Unidad de Medida</th>
            <th>Cliente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {materialesFiltrados.map((material) => (
            <tr key={material.id_materiales}>
              <td>{material.id_materiales}</td>
              <td>{material.nombre_material}</td>
              <td>{material.unidad_medida}</td>
              <td>{material.nombre_cliente}</td> {/* Asegúrate de que el backend envíe 'nombre_cliente' */}
              <td>
                <button className="boton-acciones editar" onClick={() => editarMaterial(material.id_materiales)}>Editar</button>
                <button className="boton-acciones eliminar" onClick={() => eliminarMaterial(material.id_materiales)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Materiales;
