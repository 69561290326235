import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../Estilos/ReporteMateriales.css';

const ReporteMateriales = () => {
  const [reportes, setReportes] = useState([]);
  const [mensajeError, setMensajeError] = useState('');
  const [filtroIdProyecto, setFiltroIdProyecto] = useState('');
  const [filtroNombreProyecto, setFiltroNombreProyecto] = useState('');

  useEffect(() => {
    const obtenerReportes = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/reporte-materiales');
        setReportes(response.data);
      } catch (error) {
        console.error('Error al obtener los reportes de materiales', error);
        setMensajeError('Hubo un error al cargar los reportes de materiales');
      }
    };
    obtenerReportes();
  }, []);

  const eliminarReporte = async (idReporte) => {
    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/reporte-materiales/${idReporte}`);
      setReportes(reportes.filter((reporte) => reporte.id_reporte !== idReporte));
    } catch (error) {
      console.error('Error al eliminar el reporte de materiales', error);
      setMensajeError('Hubo un error al eliminar el reporte');
    }
  };

  const reportesFiltrados = reportes.filter((reporte) => {
    return (
      reporte.id_proyecto.toString().includes(filtroIdProyecto.toLowerCase()) &&
      reporte.Nombre_proyecto.toLowerCase().includes(filtroNombreProyecto.toLowerCase())
    );
  });

  return (
    <div className="reporte-materiales-container">
      <h2>Reportes de Materiales</h2>
      <div className="filtros-y-boton">
        <Link to="/vista-administrativo/reporte-materiales/agregar">
          <button className="btn-agregar-reporte">Agregar Reporte Materiales</button>
        </Link>
        <div className="filtros-container">
          <input
            type="text"
            placeholder="Filtrar por ID Proyecto"
            value={filtroIdProyecto}
            onChange={(e) => setFiltroIdProyecto(e.target.value)}
          />
          <input
            type="text"
            placeholder="Filtrar por Nombre del Proyecto"
            value={filtroNombreProyecto}
            onChange={(e) => setFiltroNombreProyecto(e.target.value)}
          />
        </div>
      </div>

      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}

      <table>
        <thead>
          <tr>
            <th>ID Reporte</th>
            <th>ID Proyecto</th>
            <th>Nombre del Proyecto</th>
            <th>Nombre Técnico</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {reportesFiltrados.map((reporte) => (
            <tr key={reporte.id_reporte}>
              <td>{reporte.id_reporte}</td>
              <td>{reporte.id_proyecto}</td>
              <td>{reporte.Nombre_proyecto}</td>
              <td>{reporte.nombre_tecnico}</td>
              <td>{new Date(reporte.fecha).toLocaleDateString()}</td>
              <td className="acciones-btns">
                <Link to={`/vista-administrativo/reporte-materiales/editar/${reporte.id_reporte}`}>
                  <button className="btn-editar">Editar</button>
                </Link>
                <button className="btn-eliminar" onClick={() => eliminarReporte(reporte.id_reporte)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReporteMateriales;
