import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Roles.css'; // Asegúrate de crear e importar el archivo CSS correspondiente

const AgregarRol = () => {
  const [nuevoRol, setNuevoRol] = useState({
    nombre_rol: ''
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate(); // Para redirigir

  // Manejar los cambios en el formulario
  const handleChange = (e) => {
    setNuevoRol({
      ...nuevoRol,
      [e.target.name]: e.target.value,
    });
  };

  // Función para agregar un nuevo rol
  const agregarRol = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-gruj.onrender.com/api/roles', nuevoRol);
      setMensajeExito('Rol guardado exitosamente'); // Mensaje de éxito

      // Redirigir a la vista de Roles después de 2 segundos
      setTimeout(() => {
        navigate('/vista-administrativo/roles');
      }, 2000);
    } catch (error) {
      console.error('Error al agregar el rol:', error);
    }
  };

  return (
    <div className="gestion-roles">
      <h2>Agregar Nuevo Rol</h2>
      <form onSubmit={agregarRol} className="formulario-rol">
        <input
          type="text"
          name="nombre_rol"
          placeholder="Nombre del Rol"
          value={nuevoRol.nombre_rol}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Rol</button>
      </form>
      
      {/* Mostrar el mensaje de éxito */}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default AgregarRol;