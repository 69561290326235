import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom"; 
import '../../Estilos/EditarReporteMateriales.css';
import ModalMateriales from '../../Componentes/ModalMateriales'; 
import ModalProyectos from '../../Componentes/ModalProyectos'; 

const EditarReporteMateriales = () => {
  const { id } = useParams(); 
  const navigate = useNavigate(); 
  const [reporte, setReporte] = useState({
    id_proyecto: '',
    nombre_tecnico: '',
    materiales: [],
  });
  const [nombreProyecto, setNombreProyecto] = useState('');
  const [isModalMaterialesOpen, setIsModalMaterialesOpen] = useState(false);
  const [isModalProyectosOpen, setIsModalProyectosOpen] = useState(false);
  const [mensajeExito, setMensajeExito] = useState('');
  const [mensajeError, setMensajeError] = useState('');

  useEffect(() => {
    const obtenerReporte = async () => {
      try {
        const response = await axios.get(`https://backend-gruj.onrender.com/api/reporte-materiales/${id}`);
        if (response.data) {
          setReporte(response.data);
          setNombreProyecto(response.data.nombre_proyecto);
        } else {
          throw new Error('No se encontró el reporte');
        }
      } catch (error) {
        console.error('Error al obtener el reporte:', error);
        setMensajeError('Hubo un error al obtener el reporte.');
      }
    };
    obtenerReporte();
  }, [id]);

  const manejarCambioMaterial = (index, e) => {
    const { name, value } = e.target;
    const nuevosMateriales = [...reporte.materiales];
    nuevosMateriales[index][name] = value;
    setReporte({ ...reporte, materiales: nuevosMateriales });
  };

  const manejarCambioFormulario = (e) => {
    const { name, value } = e.target;
    setReporte({ ...reporte, [name]: value });
  };

  const manejarEnvioFormulario = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend-gruj.onrender.com/api/reporte-materiales/${id}`, reporte);
      setMensajeExito('Cambios guardados con éxito');
      setTimeout(() => {
        navigate('/vista-administrativo/reporte-materiales');
      }, 2000);
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      setMensajeError('Hubo un error al guardar los cambios.');
    }
  };

  const abrirModalMateriales = () => {
    setIsModalMaterialesOpen(true);
  };

  const cerrarModalMateriales = () => {
    setIsModalMaterialesOpen(false);
  };

  const abrirModalProyectos = () => {
    setIsModalProyectosOpen(true);
  };

  const cerrarModalProyectos = () => {
    setIsModalProyectosOpen(false);
  };

  return (
    <div className="editar-reporte-materiales-container">
      <h2>Editar Reporte de Materiales</h2>

      <form onSubmit={manejarEnvioFormulario}>
        <div className="proyecto-row">
          <label>Proyecto:</label>
          <input
            type="text"
            name="id_proyecto"
            value={reporte.id_proyecto}
            onChange={manejarCambioFormulario}
            readOnly
          />
          {nombreProyecto && <span className="nombre-proyecto">{nombreProyecto}</span>}
          <button type="button" className="btn-buscar-proyecto" onClick={abrirModalProyectos}>
            Buscar Proyecto
          </button>
        </div>

        <div className="tecnico-row">
          <label>Nombre Técnico:</label>
          <input
            type="text"
            name="nombre_tecnico"
            value={reporte.nombre_tecnico}
            onChange={manejarCambioFormulario}
            required
          />
        </div>

        {reporte.materiales.length > 0 && reporte.materiales.map((material, index) => (
          <div key={index} className="material-row">
            <input
              type="text"
              name="id_material"
              placeholder="ID Material"
              value={material.id_material}
              onChange={(e) => manejarCambioMaterial(index, e)}
              readOnly
            />
            {material.nombre_material && <span className="nombre-material">{material.nombre_material}</span>}
            <button type="button" className="btn-buscar-material" onClick={abrirModalMateriales}>
              Buscar Material
            </button>
            <input
              type="number"
              name="cantidad_usada"
              placeholder="Cantidad Usada"
              value={material.cantidad_usada}
              onChange={(e) => manejarCambioMaterial(index, e)}
              required
            />
          </div>
        ))}

        <button type="submit" className="btn-guardar">Guardar Cambios</button>
      </form>

      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
      {mensajeError && <p className="mensaje-error">{mensajeError}</p>}

      {/* Renderizamos los Modales */}
      <ModalMateriales isOpen={isModalMaterialesOpen} onClose={cerrarModalMateriales} />
      <ModalProyectos isOpen={isModalProyectosOpen} onClose={cerrarModalProyectos} />
    </div>
  );
};

export default EditarReporteMateriales;
