import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Produccion.css';

const Produccion = () => {
  const [produccion, setProduccion] = useState([]);
  const [filtroProyecto, setFiltroProyecto] = useState('');
  const [filtroMes, setFiltroMes] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    obtenerProduccion();
  }, []);

  const obtenerProduccion = async () => {
    try {
      const response = await axios.get('https://backend-gruj.onrender.com/api/produccion');
      setProduccion(response.data);
    } catch (error) {
      console.error('Error al obtener la producción:', error);
    }
  };

  const eliminarProduccion = async (id) => {
    const confirmarEliminar = window.confirm('¿Estás seguro de que deseas eliminar esta producción?');
    if (!confirmarEliminar) return;

    try {
      await axios.delete(`https://backend-gruj.onrender.com/api/produccion/${id}`);
      obtenerProduccion(); // Recargar la lista después de eliminar
    } catch (error) {
      console.error('Error al eliminar la producción:', error);
    }
  };

  const agregarProduccion = () => {
    navigate('/vista-administrativo/produccion/agregar');
  };

  const editarProduccion = (id) => {
    navigate(`/vista-administrativo/produccion/editar/${id}`);
  };

  const filtrarPorProyectoYMes = (producciones) => {
    return producciones.filter((prod) => {
      const coincideProyecto = prod.id_proyectos.toString().includes(filtroProyecto.toString());
      const coincideMes = filtroMes ? prod.mes === filtroMes : true;
      return coincideProyecto && coincideMes;
    });
  };

  return (
    <div className="gestion-produccion">
      <h2>Gestión de Producción</h2>

      <div className="filtros-boton-container">
        <button className="boton-agregar" onClick={agregarProduccion}>
          Agregar Producción
        </button>
        <div className="filtros">
          <input
            type="text"
            placeholder="Filtrar por ID Proyecto"
            value={filtroProyecto}
            onChange={(e) => setFiltroProyecto(e.target.value)}
          />
          <select value={filtroMes} onChange={(e) => setFiltroMes(e.target.value)}>
            <option value="">Filtrar por Mes</option>
            <option value="ENERO">ENERO</option>
            <option value="FEBRERO">FEBRERO</option>
            <option value="MARZO">MARZO</option>
            <option value="ABRIL">ABRIL</option>
            <option value="MAYO">MAYO</option>
            <option value="JUNIO">JUNIO</option>
            <option value="JULIO">JULIO</option>
            <option value="AGOSTO">AGOSTO</option>
            <option value="SEPTIEMBRE">SEPTIEMBRE</option>
            <option value="OCTUBRE">OCTUBRE</option>
            <option value="NOVIEMBRE">NOVIEMBRE</option>
            <option value="DICIEMBRE">DICIEMBRE</option>
          </select>
        </div>
      </div>

      <table className="tabla-produccion">
        <thead>
          <tr>
            <th>ID Proyecto</th>
            <th>Nombre Proyecto</th>
            <th>Total Sin IVA (Q)</th>
            <th>IVA (Q)</th>
            <th>Suma Total (Q)</th>
            <th>Fecha</th>
            <th>Mes</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filtrarPorProyectoYMes(produccion).map((prod) => (
            <tr key={prod.id_produccion}>
              <td>{prod.id_proyectos}</td>
              <td>{prod.Nombre_proyecto}</td>
              <td>{prod.total_sin_iva}</td>
              <td>{prod.iva}</td>
              <td>{prod.suma_total}</td>
              <td>{new Date(prod.fecha).toLocaleDateString()}</td>
              <td>{prod.mes}</td>
              <td>
                <button className="boton-acciones editar" onClick={() => editarProduccion(prod.id_produccion)}>Editar</button>
                <button className="boton-acciones eliminar" onClick={() => eliminarProduccion(prod.id_produccion)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Produccion;
