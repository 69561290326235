import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Estilos/Cliente.css';

const AgregarCliente = () => {
  const [nuevoCliente, setNuevoCliente] = useState({
    nombre_cliente: '',
    correo_cliente: '',
    telefono: '',
    direccion: '',
  });
  const [mensajeExito, setMensajeExito] = useState('');
  const navigate = useNavigate(); // Para redirigir

  // Manejar los cambios en el formulario
  const handleChange = (e) => {
    setNuevoCliente({
      ...nuevoCliente,
      [e.target.name]: e.target.value,
    });
  };

  // Función para agregar un nuevo cliente
  const agregarCliente = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-gruj.onrender.com/api/clientes', nuevoCliente);
      setMensajeExito('Cliente guardado exitosamente'); // Mensaje de éxito

      // Redirigir a la vista de Clientes después de 2 segundos
      setTimeout(() => {
        navigate('/vista-administrativo/cliente');
      }, 2000);
    } catch (error) {
      console.error('Error al agregar el cliente:', error);
    }
  };

  return (
    <div className="gestion-clientes">
      <h2>Agregar Nuevo Cliente</h2>
      <form onSubmit={agregarCliente} className="formulario-cliente">
        <input
          type="text"
          name="nombre_cliente"
          placeholder="Nombre"
          value={nuevoCliente.nombre_cliente}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="correo_cliente"
          placeholder="Email"
          value={nuevoCliente.correo_cliente}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="telefono"
          placeholder="Teléfono"
          value={nuevoCliente.telefono}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="direccion"
          placeholder="Dirección"
          value={nuevoCliente.direccion}
          onChange={handleChange}
          required
        />
        <button type="submit">Guardar Cliente</button>
      </form>

      {/* Mostrar el mensaje de éxito */}
      {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
    </div>
  );
};

export default AgregarCliente;
