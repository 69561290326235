import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../Estilos/HMateriales.css';

const HMateriales = () => {
  const [hojas, setHojas] = useState([]);  // Estado para almacenar las hojas de materiales
  const [hojasOriginales, setHojasOriginales] = useState([]);  // Para mantener la lista original
  const [filtroDocumento, setFiltroDocumento] = useState('');  // Filtro por número de documento
  const [filtroIDProyecto, setFiltroIDProyecto] = useState('');  // Filtro por ID de proyecto
  const [filtroNombreProyecto, setFiltroNombreProyecto] = useState('');  // Filtro por nombre de proyecto
  const navigate = useNavigate();  // Hook para redireccionar

  useEffect(() => {
    // Obtener las hojas de materiales desde el backend
    axios.get('https://backend-gruj.onrender.com/api/h_materiales')
      .then(response => {
        setHojas(response.data);  // Almacenar los datos obtenidos en el estado
        setHojasOriginales(response.data);  // Guardar también la lista original
      })
      .catch(error => console.error('Error al obtener las hojas de materiales:', error));
  }, []);

  const handleDelete = (id) => {
    // Mostrar una ventana de confirmación antes de eliminar
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar esta hoja de materiales?");
    if (confirmDelete) {
      // Si el usuario confirma, eliminar la hoja de materiales por ID
      axios.delete(`https://backend-gruj.onrender.com/api/h_materiales/${id}`)
        .then(() => {
          // Actualizar la lista de hojas después de eliminar
          const hojasActualizadas = hojas.filter(hoja => hoja.id_hoja !== id);
          setHojas(hojasActualizadas);
          setHojasOriginales(hojasActualizadas);
        })
        .catch(error => console.error('Error al eliminar la hoja de materiales:', error));
    }
  };

  // Funciones de filtrado
  const handleFiltroDocumento = (e) => {
    const filtro = e.target.value;
    setFiltroDocumento(filtro);
    filtrarHojas(filtro, filtroIDProyecto, filtroNombreProyecto);
  };

  const handleFiltroIDProyecto = (e) => {
    const filtro = e.target.value;
    setFiltroIDProyecto(filtro);
    filtrarHojas(filtroDocumento, filtro, filtroNombreProyecto);
  };

  const handleFiltroNombreProyecto = (e) => {
    const filtro = e.target.value;
    setFiltroNombreProyecto(filtro);
    filtrarHojas(filtroDocumento, filtroIDProyecto, filtro);
  };

  // Función para aplicar los filtros
  const filtrarHojas = (documento, idProyecto, nombreProyecto) => {
    let hojasFiltradas = hojasOriginales;

    if (documento !== '') {
      hojasFiltradas = hojasFiltradas.filter(hoja =>
        hoja.no_documento.toLowerCase().includes(documento.toLowerCase())
      );
    }

    if (idProyecto !== '') {
      hojasFiltradas = hojasFiltradas.filter(hoja =>
        hoja.id_proyecto.toString().includes(idProyecto)
      );
    }

    if (nombreProyecto !== '') {
      hojasFiltradas = hojasFiltradas.filter(hoja =>
        hoja.Nombre_proyecto.toLowerCase().includes(nombreProyecto.toLowerCase())
      );
    }

    setHojas(hojasFiltradas);
  };

  return (
    <div className="container">
      <h1>Hojas de Materiales</h1>
      <button
        className="btn btn-success"
        onClick={() => navigate("/vista-administrativo/h-materiales/agregar")}
      >
        Agregar Hoja de Materiales
      </button>

      {/* Filtros */}
      <div className="filtros mt-3">
        <input
          type="text"
          placeholder="Filtrar por No Documento"
          value={filtroDocumento}
          onChange={handleFiltroDocumento}
          className="form-control"
        />
        <input
          type="text"
          placeholder="Filtrar por ID Proyecto"
          value={filtroIDProyecto}
          onChange={handleFiltroIDProyecto}
          className="form-control"
        />
        <input
          type="text"
          placeholder="Filtrar por Nombre Proyecto"
          value={filtroNombreProyecto}
          onChange={handleFiltroNombreProyecto}
          className="form-control"
        />
      </div>

      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th>No Documento</th>
            <th>ID Proyecto</th>
            <th>Nombre Proyecto</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {hojas.map((hoja) => (
            <tr key={hoja.id_hoja}>
              <td>{hoja.no_documento}</td>
              <td>{hoja.id_proyecto}</td>
              <td>{hoja.Nombre_proyecto}</td>
              <td>{hoja.fecha ? new Date(hoja.fecha).toLocaleDateString() : 'Sin Fecha'}</td>
              <td>
                <Link to={`/vista-administrativo/h-materiales/editar/${hoja.id_hoja}`} className="btn btn-warning">
                  Editar
                </Link>
                <button className="btn btn-danger ml-2" onClick={() => handleDelete(hoja.id_hoja)}>
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HMateriales;
