import React from 'react';
import BarraNavegacion from '../Componentes/BarraNavegacion';
import PiePagina from '../Componentes/PiePagina';
import '../Estilos/Principal.css';

const Inicio = () => {
  return (
    <div>
      <BarraNavegacion />
      <header className="header">
        <h1>Bienvenidos a J&O Comunicaciones S.A.</h1>
        <p>Especialistas en infraestructura de telecomunicaciones. Desde nuestra fundación, hemos trabajado con un enfoque en la innovación, la responsabilidad y el compromiso con el éxito de cada proyecto.</p>
      </header>
      
      <section className="objetivos">
        <div className="card">
          <h2>Nuestro Objetivo</h2>
          <p>Proporcionar soluciones integrales de telecomunicaciones que satisfagan las necesidades de nuestros clientes mediante el uso de tecnología avanzada y un servicio al cliente excepcional.</p>
        </div>
        <div className="card">
          <h2>Nuestra Misión</h2>
          <p>Proveer servicios de telecomunicaciones confiables y de alta calidad, adaptándonos a las necesidades de nuestros clientes con innovación y tecnología avanzada, para mejorar la conectividad y comunicación en Guatemala.</p>
        </div>
        <div className="card">
          <h2>Nuestra Visión</h2>
          <p>Ser la empresa líder en telecomunicaciones en Guatemala, reconocida por nuestra excelencia en el servicio, innovación tecnológica y compromiso con la satisfacción del cliente.</p>
        </div>
      </section>
      
      <section className="servicios">
        <h2>Nuestros Servicios</h2>
        <div className="service-grid">
          <div>
            <img src="/Imagenes/redes_fibra_optica.jpeg" alt="Redes de fibra óptica" />
            <p>Instalación y mediciones de redes de cobre y fibra óptica.</p>
          </div>
          <div>
            <img src="/Imagenes/postes_diversos_materiales.jpeg" alt="Postes de diversos materiales" />"
            <p>Canalizaciones y postes de diversos materiales.</p>
          </div>
          <div>
            <img src="/Imagenes/domotica.jpg" alt="Domótica" />
            <p>Sistemas de domótica, proporcionando soluciones de automatización y vigilancia.</p>
          </div>
          <div>
            <img src="/Imagenes/cables_aereos.jpg" alt="Cables aéreos" />
            <p>Instalación de cables aéreos, en ductos e interiores.</p>
          </div>
        </div>
      </section>

      <PiePagina />
    </div>
  );
};

export default Inicio;
