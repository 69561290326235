import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Estilos/ModalProyectos.css';

const ModalProyectos = ({ isOpen, onClose }) => {
  const [proyectos, setProyectos] = useState([]);
  const [filtroId, setFiltroId] = useState(''); // Estado para el filtro por ID
  const [filtroNombre, setFiltroNombre] = useState(''); // Estado para el filtro por Nombre

  useEffect(() => {
    const obtenerProyectos = async () => {
      try {
        const response = await axios.get('https://backend-gruj.onrender.com/api/proyectos');
        console.log(response.data); // Verificar los datos que devuelve el backend
        setProyectos(response.data);
      } catch (error) {
        console.error('Error al obtener los proyectos', error);
      }
    };

    if (isOpen) {
      obtenerProyectos();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  // Función para filtrar los proyectos por ID y Nombre
  const proyectosFiltrados = proyectos.filter((proyecto) => {
    const filtroIdCoincide = proyecto.id_proyectos
      .toString()
      .toLowerCase()
      .includes(filtroId.toLowerCase());
    const filtroNombreCoincide = proyecto.Nombre_proyecto
      .toLowerCase()
      .includes(filtroNombre.toLowerCase());
    return filtroIdCoincide && filtroNombreCoincide;
  });

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Información de Proyectos</h2>
          <button className="modal-close" onClick={onClose}>
            X
          </button>
        </div>
        <div className="modal-content">
          {/* Filtros de búsqueda */}
          <div className="filtros">
            <input
              type="text"
              placeholder="Filtrar por ID"
              value={filtroId}
              onChange={(e) => setFiltroId(e.target.value)}
              className="input-filtro"
            />
            <input
              type="text"
              placeholder="Filtrar por Nombre"
              value={filtroNombre}
              onChange={(e) => setFiltroNombre(e.target.value)}
              className="input-filtro"
            />
          </div>

          <table className="project-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Cliente</th>
              </tr>
            </thead>
            <tbody>
              {proyectosFiltrados.length > 0 ? (
                proyectosFiltrados.map((proyecto) => (
                  <tr key={proyecto.id_proyectos}>
                    <td>{proyecto.id_proyectos || 'N/A'}</td>
                    <td>{proyecto.Nombre_proyecto || 'N/A'}</td>
                    <td>{proyecto.nombre_cliente || 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No hay proyectos disponibles</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ModalProyectos;
