import React from 'react';
import '../Estilos/Principal.css';

const PiePagina = () => {
  return (
    <footer className="pie-pagina">
      <p>Dirección: 13 Calle A 12-33 Zona 17 Colonia El Maestros Guatemala, Guatemala, Guatemala</p>
      <p>PBX: (502) 4778-6385 - (502) 2255-1953 | Email: lcl.jyocomunicaciones@gmail.com</p>
      <p>Derechos reservados | Creador: Erick Lazaro</p>
    </footer>
  );
};

export default PiePagina;
